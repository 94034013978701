import * as moment from 'moment';

import {AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from "@angular/router";
import {ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {HttpEvent, HttpEventType} from "@angular/common/http";
import {Subject, Subscription, debounceTime} from 'rxjs';

import {AccountTypes} from "../../models/account-types-model";
import {AppWarningModalComponent} from '../app-warning-modal/app-warning-modal.component';
import {AuthState} from '../../action-state/states/auth.state';
import {CdkVirtualScrollViewport} from '@angular/cdk/scrolling';
import {Clipboard} from '@angular/cdk/clipboard';
import {GalleryPreviewComponent} from '../gallery/gallery-preview.component';
import {HelperService} from 'src/app/services/helper.service';
import {IndexDbService} from "../../services/indexDb.service";
import {JoyrideService} from 'ngx-joyride';
import {Location} from '@angular/common';
import {NzMessageService} from "ng-zorro-antd/message";
import {NzModalService} from "ng-zorro-antd/modal";
import {NzUploadFile} from "ng-zorro-antd/upload";
import {PinterestService} from "../../services/pinterest.service";
import {RepeatPostComponent} from "../repeat-post/repeat-post.component";
import {ScheduleComponent} from "../schedule/schedule.component";
import {SharedService} from "../../services/shared.service";
import {ShepherdService} from 'angular-shepherd';
import {SocialAccountService} from "../../services/social-account.service";
import {Store} from "@ngxs/store";
import {TeamState} from 'src/app/action-state/states/team.state';
import {TiktokService} from 'src/app/services/tiktok.service';
import {TwitterService} from 'src/app/services/twitter.service';
import TwitterText from 'twitter-text';
import {UserGalleryService} from "../../services/userGallery.service";
import {UserService} from "../../services/user.service";
import {WasabiService} from "../../services/wasabi.service";
import {YoutubeService} from "../../services/youtube.service";

@Component({
  selector: 'app-post',
  templateUrl: './post.component.html',
  styleUrls: ['./post.component.less']
})
export class PostComponent implements OnInit,OnDestroy {

  private formChangeSubscription: Subscription;
  staticConversation: any = Array(5).fill(0).map((x, i) => i);
  staticConversationHash: any = Array(8).fill(0).map((x, i) => i);

  isVisible = false;
  isexistingTemp = false;
  isSelectedAccountToggle = null;

  isWriteForMe = false;
  isDirty = false;
  @Input() id: string = '';
  @Input() copyId: string = '';
  @Input() content: string = '';
  @Input() images: any = [];
  @Input() type = 'image';
  @Input() isFeedPost = false;
  @Output() changePostSatus = new Subject();
  templateForm: any;
  hashTag = false;
  public boardName: any = '';
  public accounts: any = [];
  public postForm: FormGroup;
  public accountProvider = this.sharedService.twitterProvider;
  public allAccountProvider: any = [];
  public selectedAccounts: any = [];
  public uploadFiles = [];

  pinterestBoards: any = [];
  pinterestAccounts: any = [];
  pinterestBoardIds = {};
  pinterestBoardNames = {};
  pinterestBoardId: any = [];
  isCommentVisible = false;
  isCommentVisibleForYT = false;
  isCommentVisibleForLinkedIn = false;
  isCommentVisibleForInstagram = false;

  tiktokAccounts: any = [];

  public userRole = '';
  public allAccountRole: any = [];
  public isMultipleRoleSelected = false;
  public currentPageRole = 'Custom';

  validationErrors = [];
  listOfVideoTags: any = [];
  youtubeThumbnails: any = [];
  youtubeCategories: any = [];

  instaReelThumbnails: any = [];
  public maxLength = 10;
  public mediaData: any = [];
  public tempMediaData: any = [];
  public galleryMediaData: any = [];
  public deletedPhotos: any[] = [];
  public deletedGalleryMedias = [];
  public provider = AccountTypes.getObjectAccountRequest('');
  isVideoValid = true;
  isVideoUploaded = false;
  imageFile = [];
  loading = false;
  percent = 0;
  public timerInterval: any;
  tempPost = false;
  error = '';
  selectedGalleryFile: any = '';
  selectedImageURL: any = '';
  cropTool = false;
  currentUploading = [];

  fileMetaDatas = [];
  fileTypes = [];
  mediaFiles = [];
  supportedFileTypes = ['image/jpg', 'image/jpeg', 'image/png', 'image/gif', 'video/mov', 'video/quicktime', 'video/mp4', 'video/m4v'];
  supportedMediaFiles = ".jpg, .jpeg, .png, .mov, .mp4, .m4v";

  public currentPost: any = null;
  public postDate: Date | null = null;
  public postTime: Date | null = null;
  repeatPost = false;
  postStatus = '';
  errorProvider = null;
  errorAccount = {};
  showErrorPopup = false;

  repeatPostType = '';
  repeatPostStartDate: any;
  repeatPostEndDate: any;
  oldAccounts = [];
  isDirectUpload = false;
  isEditImage = false;
  editImageIndex = 0;
  closeCropModal: any;

  @Output() model = [];
  @ViewChild('inputContent')


  inputContent: ElementRef;
  page: any = 1;

  isLoading = false;
  loadingPostType = '';
  @Input() isLoadingPost = false;

  allTemplate: any = [];

  isBoardCreating = false;
  editTemplateId = 0;

  modalTitle = 'Create new template';
  buttonName = 'Save'
  templateContent = '';
  subject = new Subject();
  params: any;
  searchResult: any = [];
  hashList: any = [];
  userSocialId = '';
  tabType = '';
  selectedHashList = [];
  selectedHash = 0;
  selectedValue = '';

  templateId = null;
  @ViewChild(CdkVirtualScrollViewport)
  viewport: CdkVirtualScrollViewport;
  isSpinning = false
  searchPage = 1;
  searchText = '';
  loadingMore = false;
  isHashLoading = false;
  allHash = [];
  connectTwitter = false;
  destroySearch;
  isLoadingHas = false;
  emptySearchLoading = false

  //...
  @Input() postName: string = null;

  teams: any = [];
  assigneeUsers = [];
  isAssigneeUsersLoading = false;
  selectedAssigneeUsers = [];
  selectedPriority = null;
  selectedTeam: any;
  user: any;

  hashtagLimit = 10;
  hashtagPosition = 'auto';
  isAutoHashtagLoading = false;
  isVisibleHashtagModal = false;
  autoHashtagVisible = false;

  /*Auto Hashtag*/
  autoHashTag = false;
  hashtagsFromText: any = [];
  selectedAutoHashtags = [];

  /*chat GPT*/
  isArticleLoading = false;
  platformTexts = [];
  toneTypes = [];
  articles = [];
  selectedPlatformText='';
  selectedTone='';
  articleText='';
  articleTextLimit=2000;
  remaining_chatgpt_article_limit = -1;
  total_chatgpt_article_limit = -1 ;
  //team dropdown
  ddTeamClass: any = "post_accounts";
  isFormSubmitted = false;
  get creatorInfo(): AbstractControl[] {
    return this.postForm && (this.postForm.get("tiktok_creator_info") as FormArray).controls ? (this.postForm.get("tiktok_creator_info") as FormArray).controls : [];
  }

  isPaymentModalVisible=false;
  selectedAddon=null;
  isAccountConnectModal=false;
  httpAccountSubscription: any = null;

  isShowLocation = false;
  selectedLocation = null;

  isLocationLoading=false;
  postLocations = []
  httpFacebookLocationSubscription: any = null;

  schedulePostDetailLoading = false;
  isPlatFormSpecific = false;

  isFacebookPageSearchLoading=false;
  facebookPages = []
  httpFacebookPageSearchSubscription: any = null;
  selectedFacebookMentions = [];
  facebookSearchPagesList = [];

  selectedInstagramMentions = [];
  instagramSearchPagesList = [];

  selectedTwitterMentions = [];
  twitterSearchPagesList = [];

  selectedThreadsMentions = [];
  threadsSearchPagesList = [];

  selectedTiktokMentions = [];
  tiktokSearchPagesList = [];

  httpSubscriptionAssignUsers: any = null;
  userId = null;
  betaUsers = [2,3,140,60];
  private facebookPageSearchSubject = new Subject<string>();

  isShowHashTagGenerate = false;
  hashTagImg = null;
  selectedHashtags: string[] = [];
  hashtags = [];
  isHashTagLoading = false;
  httpSubscriptionHashtag = null;
  isGalleryImageLoading = true;
  isVideoHashTag = false;
  httpSubscriptionHashtags: any = null;

  dummyItems = Array(10).fill(0).map((x, i) => i);

  isImageGenerateModal = false;
  isImageGenerating = false;
  isImageRegenerating = false;
  needRegenerate = false;
  isPreviewGeneratedImageModal = false;
  previewGeneratedImage = '';
  generatedImages = [];
  textToImage='';
  httpSubscriptionImageGenerate: any = null;
  dummyImages = Array(3).fill(0).map((x,i)=>i);
  selectedGeneratedImage = [];
  isMediaRearranging = false;
  multiImages = [];
  refineTextToImage='';


  aiImagePrompt = false;

  styleSelection = 'No Style';
  styleAspectRatio = '1:1';
  styles = [
    { name: 'No Style', imageUrl: 'NoStyle.png', value: 'No Style' },
    { name: 'Hyperreal', imageUrl: 'Hyperreal.png', value: 'Hyperreal' },
    { name: 'Portrait', imageUrl: 'Portrait.png', value: 'Portrait' },
    { name: 'Cartoon', imageUrl: 'cartoon.png', value: 'Cartoon' },
    { name: '3D Render', imageUrl: '3DRender.png', value: '3D Render' },
    { name: 'Anime', imageUrl: 'Anime.png', value: 'Anime' },
    { name: 'Picasso', imageUrl: 'picasso.png', value: 'Picasso' },
    { name: 'Studio', imageUrl: 'Studio.png', value: 'Studio' },
    { name: 'Christmas', imageUrl: 'christmas.png', value: 'Christmas' },
    //{ name: 'Goncharo', imageUrl: 'goncharo.png', value: 'Goncharo' },
    { name: 'Photo', imageUrl: 'Photo.png', value: 'Photo' },
    { name: 'Cyberpunk', imageUrl: 'Cyberpunk.png', value: 'Cyberpunk' },
    { name: 'Oil Painting', imageUrl: 'Oilpainting.png', value: 'Oil Painting' },
    { name: 'Pencil', imageUrl: 'Pencil.png', value:'detailed pencil sketch' },
    { name: 'Aivazovsk', imageUrl: 'aivazovsk.png', value: 'Aivazovsk' },
    { name: 'Classicism', imageUrl: 'classicism.png', value: 'Classicism' },
  ];

  groupedAccounts = [];
  isOpenSelect:any;
  @Input() postMedias = [];

  uploadImgError:any = [];
  isDirectMultiUpload:boolean = false;
  directMultipleUpload = [];
  isDirectMultiUploadSubscription: Subscription;

  remainingCharacters: { [provider: string]: number } = {};
  isContentWarning: { [provider: string]: boolean } = {};

  private contentChangeSubscription: Subscription;


  constructor(private socialAccountService: SocialAccountService,
              public sharedService: SharedService,
              private pinterestService: PinterestService,
              private youtubeService: YoutubeService,
              private formBuilder: FormBuilder,
              private messageService: NzMessageService,
              private modal: NzModalService,
              private wasabiService: WasabiService,
              private store: Store,
              private router: Router,
              private changeDet: ChangeDetectorRef,
              private location: Location,
              private indexDbService: IndexDbService,
              private route: ActivatedRoute,
              private twitterService: TwitterService,
              private tiktokService: TiktokService,
              private activatedRoute: ActivatedRoute,
              private clipboard: Clipboard,
              private shepherdService: ShepherdService,
              private userService: UserService,
              private userGalleryService: UserGalleryService,
              private _joyride:JoyrideService
  ) {
    this.activatedRoute.params.subscribe(param => {
      this.params = param;
    });
  }

  async ngOnInit(){
    await this.initForm();

    this.userId = this.store.selectSnapshot(AuthState.user).id;
    this.selectedPriority = this.sharedService.priorities[0];
    this.modalTitle = 'Create New Template'
    localStorage.removeItem('reconnect_popup');
    localStorage.removeItem('reconnect_id');
    localStorage.removeItem('error_display');
    localStorage.removeItem('reconnect_popup');
    localStorage.removeItem('has_reconnect_error');

    this.user = this.store.selectSnapshot(AuthState.user);
    console.log("this.user : ", this.user);
    this.userRole = this.store.selectSnapshot(TeamState.role);
    this.getArticleSettings();
    if (this.sharedService.currentPlan?.plan_id === 'plan_1') {
      console.log("plan_1 ", this.sharedService.currentPlan);
      await this.getActiveAccounts();
    }
    this.getYoutubeCategories();

    //get page from url
    this.page = this.route.snapshot.queryParamMap.get('page');
    this.teams = this.sharedService.getTeamList();
    await this.setDefaultTeam();

    this.templateForm = this.formBuilder.group({
      template_name: [null],
      template_description: [null],
    });

    this.subject.pipe(debounceTime(500)).subscribe((res: any) => {
        //console.log("onSearchEvent");
        this.onSearchEvent(res.search, res.page);
    });

    this.suggestion();
    this.formChangeSubscription = this.postForm.valueChanges.subscribe((changes) => {
      if (!this.isFormSubmitted && (this.postForm.value.content != '' || this.images.length > 0)) {
        this.changePostSatus.next({isDirty: true});
      }

      if (!this.isFormSubmitted && (this.postForm.value.content == '' && this.images.length == 0)) {
        this.changePostSatus.next({isDirty: false});
      }
    });

    this.getAddons();

    console.log("ngOnInit isFeedPost",this.isFeedPost)
    if (!this.isFeedPost) {
      this.stepperInit();
    }

    this.facebookPageSearchSubject.pipe(
        debounceTime(300) // Adjust the debounce time as needed
    ).subscribe((event) => {
      this.performFacebookPageSearch(event);
    });

    this.groupAccountsByProvider();

    this.sharedService.hashtagGenerateModal.subscribe((res:any)=>{
      console.log(res,"res ------");
      this.isShowHashTagGenerate = res.isVisible;
      this.hashTagImg = res.imageUrl;
      this.isVideoHashTag = res.isVideo;

      if(this.isShowHashTagGenerate == true){
        this.getHashTagGenerate();
      }

    })
    this.isDirectMultiUploadSubscription = this.sharedService.isDirectMultiUpload.subscribe((res: any) => {
      // console.log(res, "res in ngOnInit");
      this.isDirectMultiUpload = res;
    });


   /* this.postForm.get('content')?.valueChanges
      .pipe(debounceTime(300)) // Adjust delay as needed
      .subscribe(() => {
        console.log("content valueChanges")
        this.allAccountProvider.forEach((provider) => this.updateRemainingCharacters(provider));
      });*/

    /*this.postForm.get('content')?.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe(() => {
        console.log("content valueChanges");
        this.allAccountProvider.forEach((provider) => this.updateRemainingCharacters(provider));
      });*/

    this.contentChangeSubscription = this.postForm.get('content')?.valueChanges
      .pipe(debounceTime(300)) // Wait 300ms after the last change
      .subscribe(() => {
        console.log("content valueChanges");
        this.allAccountProvider.forEach((provider) => this.updateRemainingCharacters(provider));
    });
  }

  private updateRemainingCharacters(provider: string): void {
    console.log("content valueChanges updateRemainingCharacters")
    const maxContentLength = this.sharedService.getContentLength(provider);
    const content = this.postForm.get('content')?.value?.trimStart() || '';
    this.remainingCharacters[provider] = maxContentLength - content.length;
    this.isContentWarning[provider] = this.remainingCharacters[provider] <= 0;
  }

  getHashTagGenerate():void{
    this.isHashTagLoading = true;
    if (this.httpSubscriptionHashtags !== null) {
      this.httpSubscriptionHashtags.unsubscribe();
    }
    if(!this.isVideoHashTag){
      this.httpSubscriptionHashtags = this.userGalleryService.generateHashtags(this.hashTagImg).subscribe((res) => {
        if (res.code == 200) {
          this.hashtags = res.data;
          this.isHashTagLoading = false;
          console.log("res.data",res.data);
        }
      });
    } else{
      this.httpSubscriptionHashtags = this.userGalleryService.generateVideoHashtags(this.hashTagImg).subscribe((res) => {
        if (res.code == 200) {
          this.hashtags = res.data;
          this.isHashTagLoading = false;
          console.log("res.data",res.data);
        }
      });
    }
  }

  getHashTags(event){
    console.log(event,"getHashTags event");
    this.selectedHashtags = event;
  }

  addHashtagsToPost(): void {
    const hashtagsString = this.selectedHashtags.join(', ');
    if(this.postForm.get('content').value != null){
      var appendContent = this.postForm.get('content').value + " " + hashtagsString;
    }else{
      var appendContent = hashtagsString;
    }
    this.postForm.get('content').setValue(appendContent);
    this.sharedService.resetHashTagGenerateModal();
    console.log('Selected Hashtags:', hashtagsString);
  }

  hasTagGenerationCloseModal(){
    this.isShowHashTagGenerate = false;
    this.hashTagImg = '';
    this.sharedService.resetHashTagGenerateModal();
    this.groupAccountsByProvider();
  }

  stepperInit(){
    // console.log("stepperInit accounts",this.accounts)
    // console.log("stepperInit create post need_tour",this.sharedService.need_tour)
    if(this.sharedService.need_tour && this.accounts.length>0) {


      /*this.shepherdService.defaultStepOptions = {
        classes: 'custom-class-name-1 custom-class-name-2',
        scrollTo: false,
        cancelIcon: {
          enabled: true
        }
      }

      this.shepherdService.modal = true;
      this.shepherdService.confirmCancel = false;
      let steps = [];
      if(this.sharedService.currentPlan.plan_id!== 'plan_1'){
        //team
        steps.push({
          id: 'intro',
          attachTo: {
            element: '.intro_select_team',
            on: 'bottom'
          },
          beforeShowPromise: function() {
            return new Promise(function(resolve) {
              setTimeout(function() {
                window.scrollTo(0, 0);
                resolve(true);
              }, 500);
            });
          },
          buttons:[
            {
              classes: 'shepherd-button-secondary',
              text: 'Exit tutorial',
              secondary:true,
              action: () => {
                this.sharedService.endTour();
                this.shepherdService.complete();
              }
            },
            {
              classes: 'shepherd-button-primary',
              text: "Next",
              secondary:true,
              action: () => {
                this.shepherdService.next();
              }
            }
          ],
          cancelIcon: {
            enabled: true
          },
          classes: 'custom-class-name-1 custom-class-name-2',
          highlightClass: 'highlight',
          scrollTo: false,
          title: 'Select Team',
          text: ["Select Team "],
          when: {
            show: () => {
              console.log('show step');
            },
            hide: () => {
              console.log('hide step');
            }
          }
        });
      }
      steps.push({
        id: 'intro',
        attachTo: {
          element: '.intro_post_to',
          on: 'bottom'
        },
        beforeShowPromise: function() {
          return new Promise(function(resolve) {
            setTimeout(function() {
              window.scrollTo(0, 0);
              resolve(true);
            }, 500);
          });
        },
        buttons:[
          {
            classes: 'shepherd-button-secondary',
            text: 'Exit tutorial',
            secondary:true,
            action: () => {
              this.sharedService.endTour();
              this.shepherdService.complete();
            }
          },
          {
            classes: 'shepherd-button-primary',
            text: 'Previous',
            secondary:true,
            action: () => {
              this.shepherdService.back();
            }
          },
          {
            classes: 'shepherd-button-primary',
            text: "Next",
            secondary:true,
            action: () => {
              this.shepherdService.next();
            }
          }
        ],
        cancelIcon: {
          enabled: true
        },
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        scrollTo: true,
        title: 'Select social networks',
        text: ["Select one or more social network accounts to publish to. Publishing to multiple accounts increases your reach. We've selected some to get you started."],
        when: {
          show: () => {
            console.log('show step');
          },
          hide: () => {
            console.log('hide step');
          }
        }
      });
      steps.push({
        id: 'intro',
        attachTo: {
          element: '.intro_description',
          on: 'bottom'
        },
        beforeShowPromise: function() {
          return new Promise(function(resolve) {
            setTimeout(function() {
              window.scrollTo(0, 0);
              resolve(true);
            }, 500);
          });
        },
        buttons:[
          {
            classes: 'shepherd-button-secondary',
            text: 'Exit tutorial',
            secondary:true,
            action: () => {
              this.sharedService.endTour();
              this.shepherdService.complete();
            }
          },
          {
            classes: 'shepherd-button-primary',
            text: 'Previous',
            secondary:true,
            action: () => {
              this.shepherdService.back();
            }
          },
          {
            classes: 'shepherd-button-primary',
            text: "Next",
            secondary:true,
            action: () => {
              this.shepherdService.next();
            }
          }
        ],
        cancelIcon: {
          enabled: true
        },
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        scrollTo: true,
        title: 'Write your post',
        text: ["Add text, links, and maybe a sprinking of #hashtags to your post. Then customize it for each social network."],
        when: {
          show: () => {
            console.log('show step');
          },
          hide: () => {
            console.log('hide step');
          }
        }
      });
      steps.push({
        id: 'intro',
        attachTo: {
          element: '.intro_post_actions',
          on: 'bottom'
        },
        beforeShowPromise: function() {
          return new Promise(function(resolve) {
            setTimeout(function() {
              window.scrollTo(0, 0);
              resolve(true);
            }, 500);
          });
        },
        buttons:[
          {
            classes: 'shepherd-button-secondary',
            text: 'Exit tutorial',
            secondary:true,
            action: () => {
              this.sharedService.endTour();
              this.shepherdService.complete();
            }
          },
          {
            classes: 'shepherd-button-primary',
            text: 'Previous',
            secondary:true,
            action: () => {
              this.shepherdService.back();
            }
          }
        ],
        cancelIcon: {
          enabled: true
        },
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        scrollTo: true,
        title: 'Schedule your post',
        text: ["Pick a date and time, or select a recommended time, and we'll publish this post for you - even if you're in bed, at brunch, or off to the Bahamas."],
        when: {
          show: () => {
            console.log('show step');
          },
          hide: () => {
            console.log('hide step');
          }
        }
      });

      this.shepherdService.addSteps(steps);*/
      // this.shepherdService.start();



      // Start guid tour
      let newSteps = ['post_postTo','post_description','post_schedule'];
      // ['fourthStep','fifthStep','sixthStep','seventhStep']

      if(this.sharedService.currentPlan.plan_id!== 'plan_1'){
        newSteps = ['post_team','post_postTo','post_description','post_schedule'];
      }
      this._joyride.startTour(
        {
          steps: newSteps,
          stepDefaultPosition: 'top',
          //  startWith:'secondStep'
        } // Your steps order
      ).subscribe(
        (step) => {
          /*Do something*/
          // setTimeout(() => {
          //   window.scroll({
          //     top: 0,
          //     left: 0,
          //     behavior: 'smooth'
          //   });
          // }, 100);
          if(step.name == 'post_team'){
            setTimeout(() => {
              window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth'
              });
            }, 0.1);
          }
          console.log(step);
          document.body.id= step.name;

        },
        (error) => {
          /*handle error*/
        },
        () => {
          /*Tour is finished here, do something*/
          document.querySelector('body').removeAttribute('id');
          this.sharedService.endTour();
        }
      )
      // End guid tour
    }
  }

  ngOnDestroy() {
    if (this.postForm.value.content != '' || this.images.length > 0) {
      console.log("ngOnDestroy open --> Model")
      return false;
    }
    console.log("ngOnDestroy no need --> Model")
    setTimeout(() => {
      this._joyride.closeTour();
    }, 500);
    if (this.isDirectMultiUploadSubscription) {
      this.isDirectMultiUploadSubscription.unsubscribe();
    }

    this.contentChangeSubscription.unsubscribe();
  }

  async initForm(): Promise<void> {
    this.postForm = this.formBuilder.group({
      content: [this.content,
        [
          this.contentLength,
          this.facebookLength,
          this.instagramLength,
          this.linkedinLength,
          this.youtubeLength,
          this.pinterestLength,
          this.tiktokLength,
          this.threadsLength
        ],
      ],
      first_comment: [
        '',
        [
          this.instagramCommentLength,
          this.facebookCommentLength,
          this.linkedinCommentLength,
          this.youtubeCommentLength,
        ],],
      accounts_id: [[]],
      team: [[]],
      assignee_users: [[]],

      title: ['', [this.youtubeTitleLength, this.pinterestTitleLength]],
      privacy: ['public'],
      category: [22],
      youtube_video_tag: [],
      audience: ['false'],

      pinterest_board_id: null,
      pinterest_link: null,
      board_name: null,

      tiktok_creator_info: this.formBuilder.array([]),
      // instagram reels
      instagram_type: ['feed_post'],
      is_instagram_standard_post: false,
      is_instagram_reels_post: true,
      instagram_collaborators:[]

    });

    if (this.isFeedPost) {
      //this.postForm.get('content').disable();
      this.postForm.get('first_comment').disable();
    }

    /*if (this.user.is_super_admin) {
      console.log("is_super_admin ", this.user.is_super_admin);
      await this.getActiveAccounts();
    }*/

    if (this.id) {
      await this.getSchedulePostDetail(this.id);
      //if (!this.user.is_super_admin) {
      //this.getActiveAccounts(localStorage.getItem('team_id'));
      //this.getActiveAccounts(this.currentPost.team_id);
      //}
    }
    if (this.copyId) {
      await this.getSchedulePostDetail(this.copyId);
    }

  }

  // To set Account list in dropdown
  getActiveAccounts(team_id = ""){
    console.log("getActiveAccounts team_id : ",team_id)
    if (this.httpAccountSubscription !== null) {
      this.httpAccountSubscription.unsubscribe();
    }
    this.isLoadingPost = true;
    this.httpAccountSubscription = this.socialAccountService.getSocialActiveAccountByRole(team_id, true).subscribe(
      (res) => {
        console.log("getActiveAccounts res : ", res)
        this.isLoadingPost = false;
        if (res.code === 200) {
          this.accounts = res.data;
          console.log(this.accounts,"this.accounts test");

          this.oldAccounts = res.data;
          this.accounts.forEach((item, index) => {
            this.accounts[index][`checked`] = false;
          });

          if (this.isFeedPost) {
            var notAvailablePlatforms = [
              this.sharedService.pinterestProvider,
              this.sharedService.youtubeProvider,
            ];
            var newAccounts = [];
            this.accounts.forEach((item, index) => {
              if (!notAvailablePlatforms.includes(item.provider)) {
                newAccounts.push(item);
              }
            });
            this.accounts = newAccounts;

          }else{
            this.stepperInit();
          }

          if(this.id){
            this.setEditPostData();
          }

          this.groupAccountsByProvider();
        }
      },(error) => {
        this.isLoadingPost = false;
      }
    );
  }

  groupAccountsByProvider() {
    this.groupedAccounts = this.accounts.reduce((grouped, account) => {
      const provider = account.provider;
      if (!grouped[provider]) {
        grouped[provider] = [];
      }
      grouped[provider].push(account);

      return grouped;
    }, {});
  }

  public contentError: any;
  // public titleError :any;
  setValidation(): void {
    this.accountProvider = this.sharedService.getProvider(
      this.selectedAccounts
    );
    this.provider = AccountTypes.getObjectAccountRequest(this.accountProvider);
    this.contentError = [this.contentLength, this.linkedinLength, this.instagramLength, this.facebookLength, this.youtubeLength, this.pinterestLength, this.tiktokLength , this.threadsLength]
    this.postForm.controls.content.setValidators(this.contentError);
    this.postForm.controls.content.updateValueAndValidity();
    this.postForm.controls.title.updateValueAndValidity();
    this.postForm.controls.first_comment.updateValueAndValidity();
  }

  getSchedulePostDetail(id): Promise<boolean> {
    //this.spinnerService.show();
    this.schedulePostDetailLoading = true;
    return new Promise((resolve) => {
      this.socialAccountService.getPostDetail(id).subscribe(
        (res) => {
          //this.spinnerService.hide();
          if (res.code === 200) {
            this.postStatus = res.data.post_status;
            if (!this.copyId) {

              if (res.data.post_status !== this.sharedService.schedule && !res.data.is_draft) {
                this.sharedService.displayNotification(this.sharedService.messageTypeError, this.sharedService.defaultMessageError, 'You could not edit this post.');
              }

              this.currentPost = res.data;
              if (this.currentPost.board_id !== undefined) {
                this.getPinterestBoards(this.currentPost.social_account_id);
              }

              /*this.getActiveAccounts(this.currentPost.team_id).then(res=>{
                this.setEditPostData();
              });*/

              this.getActiveAccounts(this.currentPost.team_id);

              //this.setEditPostData();
              /*setTimeout(() => {
                this.setEditPostData();
              }, 2000);*/

            } else {
              this.setCopyPostData(res.data);
            }
          }

          if (res.code === 500 && res.toast === true) {
            this.sharedService.displayNotification(this.sharedService.messageTypeError, this.sharedService.defaultMessageError, res.message);
            this.router.navigate(['/']);
          }
          resolve(true);
          this.schedulePostDetailLoading = false;
        },
        (error) => {
          //this.spinnerService.hide();
          resolve(true);
          this.schedulePostDetailLoading = false;
        }
      );
    });
  }

  setEditPostData(): void {
    console.log("setEditPostData currentPost", this.currentPost)
    console.log("setEditPostData currentPost accounts", this.accounts)
    console.log("setEditPostData currentPost teams", this.teams)

    this.postForm.patchValue({
      content: this.currentPost.content,
      // instagram reels
      //instagram_type: (this.currentPost?.instagram_type != undefined && this.currentPost?.instagram_type == 'reel_post') ? 'reel_post' : 'feed_post',
      instagram_type: (this.currentPost?.instagram_type != undefined) ? this.currentPost?.instagram_type : 'feed_post',
      is_instagram_standard_post: (this.currentPost?.is_instagram_standard_post != undefined && this.currentPost?.is_instagram_standard_post) ? true : false,
      is_instagram_reels_post: true,

      instagram_collaborators: (this.currentPost?.instagram_collaborators != undefined) ? this.currentPost?.instagram_collaborators : '',
    });

    if (this.currentPost.first_comment !== undefined) {

      this.postForm.patchValue({
        first_comment: this.currentPost.first_comment,
      });
    } else {

      this.postForm.patchValue({ first_comment: '' });
    }

    if (this.currentPost.user_social_connection) {
      console.log("selected account while setEditPostData",this.selectedAccounts);
      this.selectedAccounts = this.accounts.filter((acc) => {
        if (acc.social_id === this.currentPost.user_social_connection.social_id) {
          return acc;
        }
      });

      if (this.selectedAccounts.length == 0) {
        console.log("selectedAccounts : ",this.selectedAccounts);
        this.sharedService.displayNotification(this.sharedService.messageTypeError, 'Edit Post', 'No Active Account Found!');
      }

      this.allAccountProvider = [];
      this.selectedAccounts.forEach((item) => {
        if (!this.allAccountProvider.includes(item.provider)) {
          this.allAccountProvider.push(item.provider);
        }
      });

      if (
        !this.allAccountProvider.includes(this.sharedService.twitterProvider) &&
        !this.allAccountProvider.includes(this.sharedService.pinterestProvider) &&
        !this.allAccountProvider.includes(this.sharedService.tiktokProvider) &&
        !this.allAccountProvider.includes(this.sharedService.threadsProvider)
      ) {

        this.isCommentVisible = true;
        this.isCommentVisibleForYT = true;

      }

      if (this.allAccountProvider.includes(this.sharedService.youtubeProvider) ||
        this.allAccountProvider.includes(this.sharedService.pinterestProvider)) {
        // title
        if (this.currentPost.title !== undefined) {
          this.postForm.patchValue({
            title: this.currentPost.title,
          });
        }
      }

      if (this.allAccountProvider.includes(this.sharedService.youtubeProvider)) {

        // privacy
        if (this.currentPost.privacy !== undefined) {
          this.postForm.patchValue({
            privacy: this.currentPost.privacy,
          });
        }

        // category
        if (this.currentPost.category !== undefined) {
          this.postForm.patchValue({
            category: parseInt(this.currentPost.category)
          });
        }

        // youtube_video_tag
        if (this.currentPost.youtube_video_tag !== null && this.currentPost.youtube_video_tag !== undefined) {
          this.listOfVideoTags = this.currentPost.youtube_video_tag.split(',');
        }

        // audience
        if (this.currentPost.audience !== undefined) {
          this.postForm.patchValue({
            audience: this.currentPost.audience,
          });
        }

        this.isCommentDisplay();

        // youtube_thumbnail
        if (this.currentPost.youtube_thumbnail !== null && this.currentPost.youtube_thumbnail !== undefined) {
          this.youtubeThumbnails.push(this.currentPost.youtube_thumbnail);
        }
      }

      // Pinterest
      if (this.allAccountProvider.includes(this.sharedService.pinterestProvider)) {
        if (this.currentPost.pinterest_link !== undefined) {
          this.postForm.patchValue({
            pinterest_link: this.currentPost.pinterest_link,
          });
        }

        if (this.currentPost.board_id !== undefined) {
          this.currentPost.social_id = this.currentPost.social_account_id;
          this.currentPost.name = this.currentPost.social_account_id;
          this.pinterestAccounts.push(this.currentPost);
          // this.getPinterestBoards(this.currentPost.social_account_id);
          this.pinterestBoardIds[this.currentPost.social_account_id] = this.currentPost.board_id;
          this.pinterestBoardNames[this.currentPost.social_account_id] = this.currentPost.board_name;
          this.boardName = this.currentPost.board_name;
          //this.pinterestBoardId = this.currentPost.board_id;
          this.pinterestBoardId.push(this.currentPost.board_id);

        }
      }

      // TikTok
      if (this.allAccountProvider.includes(this.sharedService.tiktokProvider)) {
        const creatorInfo = this.currentPost.creator_info;
        const formName = this.formBuilder.group({
          'social_id': creatorInfo.social_id,
          'privacy_level': creatorInfo.privacy_level,
          'allow_comment': creatorInfo.allow_comment,
          'allow_duet': creatorInfo.allow_duet,
          'allow_stitch': creatorInfo.allow_stitch,
          'privacy_level_options': creatorInfo.privacy_level_options,
          'max_video_post_duration_sec' : creatorInfo.max_video_post_duration_sec
        });
        (this.postForm.controls['tiktok_creator_info'] as FormArray).push(formName);
        let index = this.selectedAccounts.findIndex((element) => element.social_id == creatorInfo.social_id);
        //this.selectedAccounts[index].privacy_level = JSON.parse(creatorInfo.privacy_level_options);
        this.selectedAccounts[index].privacy_level = JSON.parse(creatorInfo.privacy_level_options_display);
      }

      // Instagram
      if (this.allAccountProvider.includes(this.sharedService.instagramProvider)) {
        // instagram_reel_thumbnail
        if (this.currentPost.instagram_reel_thumbnail !== null && this.currentPost.instagram_reel_thumbnail !== undefined) {
          this.instaReelThumbnails.push(this.currentPost.instagram_reel_thumbnail);
        }
      }
    }

    /*Mention start*/
    if (this.allAccountProvider.includes(this.sharedService.facebookProvider)) {
      this.selectedFacebookMentions = this.currentPost.facebook_mentions;
    }

    if (this.allAccountProvider.includes(this.sharedService.instagramProvider)) {
      this.selectedInstagramMentions = this.currentPost.instagram_mentions;
    }

    if (this.allAccountProvider.includes(this.sharedService.twitterProvider)) {
      this.selectedTwitterMentions = this.currentPost.twitter_mentions;
    }

    if (this.allAccountProvider.includes(this.sharedService.threadsProvider)) {
      this.selectedThreadsMentions = this.currentPost.threads_mentions;
    }

    if (this.allAccountProvider.includes(this.sharedService.tiktokProvider)) {
      this.selectedTiktokMentions = this.currentPost.tiktok_mentions;
    }


    /*Mention end*/



    if (this.currentPost.post_status !== 'DRAFT') {
      this.postDate = new Date(this.currentPost.post_time * 1000);
      this.postTime = new Date(this.currentPost.post_time * 1000);
    }

    //this.setMediaData(this.currentPost);
    /*this.mediaData = this.currentPost.post_gallery.map((img) => {
      return {
        file_size: img.file_size,
        file_type: img.file_type,
        file_mime_type: img.file_mime_type,
        media_file: img.media_file,
      };
    });*/

    this.postMedias = this.currentPost.post_gallery.map((img) => {
      return {
        file_size: img.file_size,
        file_type: img.file_type,
        file_mime_type: img.file_mime_type,
        media_file: img.media_file,
      };
    });

    //this.tempMediaData = this.mediaData;
    //this.multiImages = this.mediaData.concat(this.galleryMediaData);

    this.changeDet.detectChanges();
    this.isCommentDisplay();
  }

  setCopyPostData(data): void {
    this.postForm.patchValue({
      // instagram reels
      instagram_type: (data?.instagram_type != undefined) ? data?.instagram_type : 'feed_post',
      is_instagram_standard_post: (data?.is_instagram_standard_post != undefined && data?.is_instagram_standard_post) ? true : false,
      is_instagram_reels_post: true,
    });

    // youtube_video_tag
    if (data.youtube_video_tag !== null && data.youtube_video_tag !== undefined) {
      this.listOfVideoTags = data.youtube_video_tag.split(',');
    }

    data.youtube_video_tag = []; // this resetting value for select tag
    this.postForm.patchValue(data);

    // category
    if (data.category !== undefined) {
      this.postForm.patchValue({
        category: parseInt(data.category)
      });
    }

    //this.setMediaData(data);
    /*this.mediaData = data.post_gallery.map((img) => {
      return {
        file_size: img.file_size,
        file_type: img.file_type,
        file_mime_type: img.file_mime_type,
        media_file: img.media_file,
      };
    });*/
    this.postMedias = data.post_gallery.map((img) => {
      return {
        file_size: img.file_size,
        file_type: img.file_type,
        file_mime_type: img.file_mime_type,
        media_file: img.media_file,
      };
    });
  }

  // To Set Type and Images on edit Post
  setMediaData(data): void {
    let gallery = data.post_gallery;
    const hasImage = gallery.some(item => item.file_type === 'image');
    const hasVideo = gallery.some(item => item.file_type === 'video');
    if (hasImage && hasVideo) {
      this.type = 'multi';
    }else{
      this.type = data.post_gallery.length > 0 ? data.post_gallery[0].file_type : 'image';
    }

    this.images = data.post_gallery.map((img) => img.media_file);
  }

  // Start : Platform Content length function start from here
  tweetWordCount(tweet) {
    const parseTweet = TwitterText.parseTweet(tweet);
    return parseTweet.weightedLength;
  }

  contentLength = (control: FormControl): { [s: string]: boolean | string } => {
    if (
      control.value !== null &&
      this.selectedAccounts.length > 0 &&
      control.dirty &&
      control.value.trimStart().length === 0 &&
      this.uploadFiles.length === 0 &&
      this.accountProvider !== this.sharedService.instagramProvider
    ) {
      return { required: true };
    }

    return {};
  }

  linkedinLength = (control: FormControl): { [s: string]: boolean | string } => {
    if (
      control.value !== null && this.allAccountProvider.includes(this.sharedService.linkedinProvider) &&
      control.value.trimStart().length > this.sharedService.getContentLength(this.sharedService.linkedinProvider)
    ) {
      return {
        linkedinMaxlength: 'Max limit of LinkedIn is ' + this.sharedService.getContentLength(this.sharedService.linkedinProvider) + ' characters',
      };
    }
    return {};
  }

  instagramLength = (control: FormControl): { [s: string]: boolean | string } => {
    if (
      control.value !== null && this.allAccountProvider.includes(this.sharedService.instagramProvider) &&
      control.value.trimStart().length > this.sharedService.getContentLength(this.sharedService.instagramProvider)
    ) {
      return {
        instagramMaxlength: 'Max limit of Instagram  is ' +
          this.sharedService.getContentLength(this.sharedService.instagramProvider) + ' characters',
      };
    }
    return {};
  }

  facebookLength = (control: FormControl): { [s: string]: boolean | string } => {
    if (
      control.value !== null && this.allAccountProvider.includes(this.sharedService.facebookProvider) &&
      control.value.trimStart().length > this.sharedService.getContentLength(this.sharedService.facebookProvider)
    ) {
      return {
        facebookMaxlength:
          'Max limit of Facebook is ' +
          this.sharedService.getContentLength(this.sharedService.facebookProvider) + ' characters',
      };
    }
    return {};
  }

  youtubeLength = (control: FormControl): { [s: string]: boolean | string } => {
    if (
      control.value !== null && this.allAccountProvider.includes(this.sharedService.youtubeProvider) &&
      control.value.trimStart().length > this.sharedService.getContentLength(this.sharedService.youtubeProvider)
    ) {
      return {
        youtubeMaxlength: 'Max limit of YouTube is ' +
          this.sharedService.getContentLength(this.sharedService.youtubeProvider) + ' characters',
      };
    }
    return {};
  }

  pinterestLength = (control: FormControl): { [s: string]: boolean | string } => {

    if (
      control.value !== null && this.allAccountProvider.includes(this.sharedService.pinterestProvider) &&
      control.value.trimStart().length > this.sharedService.getContentLength(this.sharedService.pinterestProvider)
    ) {
      return {
        pinterestMaxlength:
          'Max limit of Pinterest is ' +
          this.sharedService.getContentLength(this.sharedService.pinterestProvider) + ' characters',
      };
    }
    return {};
  }

  tiktokLength = (control: FormControl): { [s: string]: boolean | string } => {
    if (
      control.value !== null && this.allAccountProvider.includes(this.sharedService.tiktokProvider) &&
      control.value.trimStart().length > this.sharedService.getContentLength(this.sharedService.tiktokProvider)
    ) {
      return {
        tiktokMaxlength:
          'Max limit of TikTok is ' +
          this.sharedService.getContentLength(this.sharedService.tiktokProvider) + ' characters',
      };
    }
    return {};
  }

  threadsLength = (control: FormControl): { [s: string]: boolean | string } => {
    if (
      control.value !== null && this.allAccountProvider.includes(this.sharedService.threadsProvider) &&
      control.value.trimStart().length > this.sharedService.getContentLength(this.sharedService.threadsProvider)
    ) {
      return {
        threadsMaxlength:
          'Max limit of Threads is ' +
          this.sharedService.getContentLength(this.sharedService.threadsProvider) + ' characters.',
      };
    }
    return {};
  }



  addEmoji(event): void {
    if (event.emoji.native) {
      const selectionStart = this.inputContent.nativeElement.selectionStart;
      var currentValue = this.postForm.value.content;
      if (this.postForm.value.content && this.postForm.value.content.length > 0) {
        const newValue = currentValue.substring(0, selectionStart) + event.emoji.native + currentValue.substring(selectionStart);
        this.postForm.controls.content.setValue(newValue);
      } else {
        currentValue = event.emoji.native;
        this.postForm.controls.content.setValue(currentValue);
      }
      this.inputContent.nativeElement.selectionStart = selectionStart + event.emoji.native.length;
      this.inputContent.nativeElement.selectionEnd = selectionStart + event.emoji.native.length;
    }
  }

  // Remove invalid account from selected dropdown list
  updateAccounts(event: any): void {
    console.log("event",event);

    this.validationErrors = [];
    this.postFailed = [];
    if (event !== null) {
      this.selectedAccounts = event;
      this.allAccountProvider = [];
      this.assigneeUsers = [];
      this.allAccountRole = [];
      if (this.userRole === 'Custom') {

        event.forEach((item) => {
          if (!this.allAccountProvider.includes(item.provider)) {
            this.allAccountProvider.push(item.provider);
          }

          if (item.provider === this.sharedService.pinterestProvider &&
            !this.pinterestAccounts.includes(item)) {

            this.pinterestAccounts.push(item);
            this.getPinterestBoards(item.social_id);
          }
          if (item.provider === this.sharedService.tiktokProvider &&
            !this.tiktokAccounts.includes(item)) {
            this.tiktokAccounts.push(item);
            this.getTikTokCreatorInfo(item.social_id);
          }
          this.allAccountRole.push(item.social_profile_access_authority.role.role_name);

          // Change Account list based on role
          if (this.selectedAccounts.length === 1) {
            var firstAccountRoleId = this.selectedAccounts[0].page_role_id;
            this.accounts = this.accounts.filter(
              (item) => item.page_role_id === firstAccountRoleId
            );
          }

        });

      } else {
        event.forEach((item) => {

          if (!this.allAccountProvider.includes(item.provider)) {
            this.allAccountProvider.push(item.provider);
          }

          if (item.provider === this.sharedService.pinterestProvider &&
            !this.pinterestAccounts.includes(item)) {

            this.pinterestAccounts.push(item);
            this.getPinterestBoards(item.social_id);
          }
          if (item.provider === this.sharedService.tiktokProvider &&
            !this.tiktokAccounts.includes(item)) {
            this.tiktokAccounts.push(item);
            this.getTikTokCreatorInfo(item.social_id);
          }
        });
      }

      if (this.selectedAccounts.length === 0) {
        this.accounts = this.oldAccounts;
      }

      this.pinterestAccounts = this.selectedAccounts.filter((item) =>
        this.pinterestAccounts.includes(item)
      );

      let selectedTikTokSocialId = []
      this.tiktokAccounts = this.selectedAccounts.filter((item) => {
          selectedTikTokSocialId.push(item.social_id);
          return this.tiktokAccounts.includes(item);
        }
      );

      let tiktokCreatorinfo = (this.postForm.get("tiktok_creator_info") as FormArray);
      tiktokCreatorinfo.controls.forEach((element, index) => {
        if (!selectedTikTokSocialId.includes(element.value.social_id)) {
          tiktokCreatorinfo.removeAt(index);
        }
      });

      const data = event.findIndex((item) => {
        return (
          (item.provider === this.sharedService.facebookProvider && item.type == this.sharedService.page) ||
          item.provider === this.sharedService.instagramProvider ||
          item.provider === this.sharedService.youtubeProvider ||
          item.provider === this.sharedService.linkedinProvider
        );
      });

      this.isCommentVisible = data !== -1;
      this.setValidation();
      this.isCommentDisplay();
      this.setCropTool();
      //For remove image validation
      if(!this.accountProvider.includes(this.sharedService.instagramProvider)){
        this.postMedias.forEach((fi, index) => {
          this.postMedias[index] = { ...fi, isValid: true };
        });
        this.uploadImgError = [];
      } else {
        console.log("this.postMedias",this.postMedias);
        this.postMedias.forEach((media) => {
          console.log("media.height && media.width",media.height , media.width);
          if(media.height && media.width){
            const { errors, mediaData: updatedMediaData } = this.sharedService.validateImageRatios(media.height,media.width,media.media_file,this.postMedias);
            if (errors.length > 0) {
              this.uploadImgError = this.uploadImgError.concat(errors).filter((value, index, self) =>
                index === self.findIndex((t) => t.fileName === value.fileName)
              );
            }
            this.postMedias = updatedMediaData
          }
        });
      }
    }

    this.getAssigneeUsers();

    // for custom role. need to check unique role for selected page.
    if (this.userRole === 'Custom') {
      const unique = this.allAccountRole.filter((item, i, ar) => ar.indexOf(item) === i);
      if (unique.length > 1) {
        this.isMultipleRoleSelected = true;
      } else {
        this.isMultipleRoleSelected = false;
        this.currentPageRole = unique[0];
      }
    }
  }


  isCommentDisplay() {

    this.isCommentVisibleForYT = false;
    this.isCommentVisibleForLinkedIn = false;
    this.isCommentVisibleForInstagram = false;

    if (this.isCommentVisible) {

      //YouTube
      if (this.allAccountProvider.includes(this.sharedService.youtubeProvider)) {
        var otherAccounts = this.selectedAccounts.filter(
          (item) =>
            item.provider !== this.sharedService.youtubeProvider &&
            item.provider !== this.sharedService.pinterestProvider &&
            item.provider !== this.sharedService.twitterProvider,
        );
        if (otherAccounts.length > 0) {
          this.isCommentVisibleForYT = true;
        } else if (this.postForm.value.audience === 'false') {
          this.isCommentVisibleForYT = true;
        }
      } else {
        this.isCommentVisibleForYT = true;
      }


      //LinkedIn
      if (this.allAccountProvider.includes(this.sharedService.linkedinProvider)) {
        const otherAccounts = this.selectedAccounts.filter(
          (item) =>
            item.provider !== this.sharedService.linkedinProvider &&
            item.provider !== this.sharedService.pinterestProvider &&
            item.provider !== this.sharedService.twitterProvider,
        );

        if (otherAccounts.length > 0) {
          this.isCommentVisibleForLinkedIn = true;
        } else if (this.type === 'image' || this.type == 'gif') {
          this.isCommentVisibleForLinkedIn = true;
        }
      } else {
        this.isCommentVisibleForLinkedIn = true;
      }

      //Instagram story
      if (this.allAccountProvider.includes(this.sharedService.instagramProvider)) {
        const otherAccounts = this.selectedAccounts.filter(
          (item) =>
            item.provider !== this.sharedService.instagramProvider &&
            item.provider !== this.sharedService.pinterestProvider &&
            item.provider !== this.sharedService.twitterProvider
        );

        // visible only for feed_post
        if (otherAccounts.length > 0) {
          console.log("isCommentVisibleForInstagram otherAccounts")
          this.isCommentVisibleForInstagram = true;
        } else if (this.postForm.value.instagram_type === 'feed_post') {
          console.log("isCommentVisibleForInstagram feed_post")
          this.isCommentVisibleForInstagram = true;
        }
      } else {
        console.log("isCommentVisibleForInstagram else")
        this.isCommentVisibleForInstagram = true;
      }

      console.log("-->isCommentVisibleForInstagram instagram_type : ",this.postForm.value.instagram_type)

    }
  }

  getPinterestBoards(socialId) {
    this.pinterestService.pinterestBoards(socialId).subscribe(
      (res) => {
        if (res.code === 200) {
          this.pinterestBoards[socialId] = [...res.data];
        }

      });
  }

  /* First Comment */

  instagramCommentLength = (control: FormControl): { [s: string]: boolean | string } => {

    if (control.value !== null && this.allAccountProvider.includes(this.sharedService.instagramProvider) &&
      control.value.trimStart().length > this.sharedService.getCommentLength(this.sharedService.instagramProvider)
    ) {
      return {
        instagramCommentMaxlength:
          'Max limit of Instagram first comment  is ' +
          this.sharedService.getCommentLength(this.sharedService.instagramProvider) + ' characters',
      };
    }
    return {};
  }

  facebookCommentLength = (control: FormControl): { [s: string]: boolean | string } => {
    if (control.value !== null &&
      this.allAccountProvider.includes(this.sharedService.facebookProvider) &&
      control.value.trimStart().length > this.sharedService.getCommentLength(this.sharedService.facebookProvider)
    ) {
      return {
        facebookCommentMaxlength: 'Max limit of Facebook first comment is ' +
          this.sharedService.getCommentLength(this.sharedService.facebookProvider) + ' characters',
      };
    }
    return {};
  }

  linkedinCommentLength = (control: FormControl): { [s: string]: boolean | string } => {
    if (control.value !== null && this.type == 'image' &&
      this.allAccountProvider.includes(this.sharedService.linkedinProvider) &&
      control.value.trimStart().length > this.sharedService.getCommentLength(this.sharedService.linkedinProvider)
    ) {
      return {
        linkedinCommentMaxlength:
          'Max limit of LinkedIn first comment is ' +
          this.sharedService.getCommentLength(this.sharedService.linkedinProvider) + ' characters',
      };
    }
    return {};
  }

  youtubeCommentLength = (control: FormControl): { [s: string]: boolean | string } => {
    if (control.value !== null &&
      this.allAccountProvider.includes(this.sharedService.youtubeProvider) &&
      control.value.trimStart().length > this.sharedService.getCommentLength(this.sharedService.youtubeProvider)
    ) {
      return {
        youtubeCommentMaxlength:
          'Max limit of YouTube first comment is ' +
          this.sharedService.getCommentLength(this.sharedService.youtubeProvider) + ' characters',
      };
    }
    return {};
  }

  changeBoard(event, social_id) {


    this.pinterestBoardIds[social_id] = event;
    this.pinterestBoardId.push(event);

    this.pinterestBoards[social_id].forEach(ele => {
      if (ele.id == event) { this.boardName = ele.name; }
    });
    this.pinterestBoardNames[social_id] = this.boardName;

  }

  boardNames(name: any) {
    this.boardName = name
  }

  createPinterestBoard(input: HTMLInputElement, socialId): void {
    const board_name = input.value;
    this.boardName = board_name;


    if (board_name !== '') {

      const postData = {
        social_id: socialId,
        board_name,
      };

      this.isBoardCreating = true;
      this.pinterestService.createPinterestBoard(postData).subscribe(
        (res) => {
          this.isBoardCreating = false;

          if (res.code === 200) {
            this.sharedService.displayNotification(this.sharedService.messageTypeSuccess, this.sharedService.defaultMessageSuccess, res.message);
            // To get newly Added Board
            setTimeout(() => {
              this.getPinterestBoards(socialId);
            }, 1000);
            input.value = '';

          } else {
            this.getPinterestBoards(socialId);
            this.sharedService.displayNotification(this.sharedService.messageTypeError, this.sharedService.defaultMessageError, res.message);
          }
        });
    } else {
      this.sharedService.displayNotification(this.sharedService.messageTypeError, 'Board name', 'Please Enter Board Name');
    }
  }

  isPublishBtnDisabled(): boolean {
    if (this.currentUploading.length > 0) {
      return true;
    } else
    if (this.images.length && this.selectedAccounts.length && !this.postForm.value.content) {
      this.postForm.controls.content.clearValidators();
      this.postForm.controls.content.updateValueAndValidity();
      return false;
    } else if (this.postForm.value.content && this.selectedAccounts.length && !this.images.length) {
      return false;
    } else if (this.postForm.value.content && this.selectedAccounts.length && this.images.length) {
      return false;
    } else if (!this.postForm.value.content && !this.selectedAccounts.length && this.images.length) {
      this.postForm.controls.content.clearValidators();
      this.postForm.controls.content.updateValueAndValidity();
      return true;
    } else {
      return (this.images.length === 0);
    }
  }

  async openRepeatPopup() {
    const modal = this.modal.create({
      nzTitle: 'Repeat Post',
      nzClassName: 'repeat-modal',
      nzContent: RepeatPostComponent,
      nzComponentParams: null,
      nzCentered: false,
      nzKeyboard: false,
      nzMaskClosable: false,
      nzAutofocus: null,
      nzWidth: 414,
      nzFooter: null
    });

    modal.afterClose.subscribe(res => {
      const instance = modal.componentInstance;
      if (instance.selectedDates.length > 0) {
        this.repeatPost = true;
        this.repeatPostType = instance.selectedRepeat;
        this.repeatPostStartDate = moment(instance.repeatDate[0]).format('yyyy-MM-DD');
        this.repeatPostEndDate = moment(instance.repeatDate[1]).format('yyyy-MM-DD');
        this.loadingPostType = 'repeat';
        this.submit(instance.selectedDates);
      }
    });
  }

  openScheduleModel(): void {
    const modal = this.modal.create({
      nzTitle: 'Schedule',
      nzClassName: 'schedule-modal',
      nzContent: ScheduleComponent,
      nzComponentParams: {
        postDate: this.postDate,
        postTime: this.postTime,
        id: this.id,
      },
      nzFooter: [
        {
          label: 'Close',
          onClick: () => modal.destroy(),
        },
        {
          label: 'Ok',
          type: 'primary',
          onClick: () => {
            this.loadingPostType = 'schedule';

            var nowDate = new Date();
            const instance = modal.getContentComponent();

            //post date
            var selectedDate: any = new Date(instance.postDate).getTime();
            var selectedTime: any = new Date(instance.postTime).getTime();

            selectedDate = new Date(selectedDate);
            selectedDate = selectedDate.getDate() + '-' + (selectedDate.getMonth() + 1) +
              "-" + selectedDate.getFullYear();

            var currentDate: any = new Date(nowDate).getTime();
            currentDate = new Date(currentDate);
            currentDate = currentDate.getDate() + '-' + (currentDate.getMonth() + 1) +
              "-" + currentDate.getFullYear();

            if (selectedDate === currentDate) {

              //post time
              var selected_time: any = new Date(selectedTime);
              selected_time = selected_time.getHours();

              //now time
              var current_time = nowDate.getHours();

              if (selected_time < current_time) {
                // this.messageService.warning("Please Select future date time");
                this.sharedService.displayNotification(this.sharedService.messageTypeInfo, this.sharedService.defaultMessageError, 'Please Select future date time');
                return;
              }
            }

            this.submitSchedule(instance, modal);
          }
        },

      ],
    });
  }

  submitSchedule(instance, modal): void {
    if (instance.postDate && instance.postTime) {
      modal.destroy();
      this.postDate = instance.postDate;
      this.postTime = instance.postTime;
      this.submit({submit_type:'schedule'});
    } else {
      this.sharedService.displayNotification(this.sharedService.messageTypeInfo, this.sharedService.defaultMessageError, 'Please select date and time');
    }
  }

  postFailed = [];
  postSuccess = [];
  postAccountStatus = [];
  platformPostStatus:any;

  postResponseError = '';
  async submit(data = null) {

    //media re-arrange
    /*this.images.forEach((value, index) => {
      this.mediaData.forEach(obj => {
        if (obj.media_file === value) {
          obj.index = index;
          return true;
        }
      });
      this.galleryMediaData.forEach(obj => {
        if (obj.media_file === value) {
          obj.index = index;
          return true;
        }
      });
    });*/

    this.postMedias.forEach((value, index) => {
      value.index = index;
    });
    console.log("arrange in post component postMedias",this.postMedias);

    this.platformPostStatus = {};
    this.postResponseError = '';
    var localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    moment.tz.setDefault(localTimezone);

    if (this.selectedAccounts.length === 0) {
      this.sharedService.displayNotification(this.sharedService.messageTypeInfo, this.sharedService.defaultMessageError, 'Please select any account');
      return;
    }

    if (!this.user.is_super_admin && this.assigneeUsers.length > 0 && this.selectedAssigneeUsers.length === 0) {
      this.sharedService.displayNotification(this.sharedService.messageTypeInfo, this.sharedService.defaultMessageError, 'Please assign any user');
      return;
    }

    this.isLoading = true;
    this.isPostingModal = true;

    for (const i in this.postForm.controls) {
      this.postForm.controls[i].markAsDirty();
      this.postForm.controls[i].updateValueAndValidity();
    }

    if (this.postForm.valid) {

      this.isFormSubmitted = true;
      this.changePostSatus.next({ isDirty: false });

      const postValue = new FormData();
      if (data != null && data.submit_type != null) {
        postValue.append('post_type', data.submit_type);
        this.loadingPostType = data.submit_type;
      }

      postValue.append('timezone', Intl.DateTimeFormat().resolvedOptions().timeZone);
      postValue.append('content', this.postForm.get('content').value);
      if (this.selectedTeam?.id) {
        postValue.append('team_id', this.selectedTeam.id);
      }

      /*Assign Work*/
      if (this.selectedPriority?.id) {
        postValue.append('priority', this.selectedPriority.id);
      }

      if (this.selectedAssigneeUsers.length > 0) {
        var assigned_users = this.selectedAssigneeUsers.map(item => { return item.id });
        postValue.append('assigned_users', JSON.stringify(assigned_users));
      }

      let tiktokCreatorInfo = this.postForm.get('tiktok_creator_info') as FormGroup
      postValue.append('tiktok_creator_info', JSON.stringify(tiktokCreatorInfo.value));

      if (
        this.postForm.get('content').value !== null &&
        this.postForm.get('content').value.trimStart().length > 280 &&
        this.allAccountProvider.includes(this.sharedService.twitterProvider)
      ) {
        let content = this.sharedService.chunkString(
          this.postForm.get('content').value.trimStart(), 280);
        if (content[0] === '') {
          content = content.slice(1);
        }

        let isValid = true;
        /*content.forEach((statement) => {
          if (statement.length > 280) {
            this.messageService.remove();
            this.messageService.error(
              'twitter content error you must provide space or newline between sentence.',
              { nzDuration: 5000 }
            );
            isValid = false;
          }
        });*/

        if (!isValid) {
          this.isLoading = false;
          return;
        }
        postValue.append('multiple_content', JSON.stringify(content));
      }

      const id = this.store.selectSnapshot(AuthState.user).id;
      if (!this.id) {
        const account = this.selectedAccounts.map((item) => {
          return item.id;
        });
        postValue.append('accounts_id', JSON.stringify(account));
      }
      postValue.append('user_id', id);

      if (this.deletedPhotos.length > 0 && this.id) {
        const deletedPhotoIds = [];
        this.currentPost.post_gallery.forEach((media) => {
          /*this.deletedPhotos.forEach((deletedMedia) => {
            if (media.media_file === deletedMedia) {
              deletedPhotoIds.push(media?._id);
            }
          });*/
          if (this.deletedPhotos.includes(media.media_file)) {
            deletedPhotoIds.push(media?._id);
          }
        });
        postValue.append('deleted_photo_id', JSON.stringify(deletedPhotoIds));
      }

      //console.log("submit edit tempMediaData",this.tempMediaData);
      /*if(this.tempMediaData.length>0){
        this.mediaData = this.mediaData.filter(item => !this.tempMediaData.includes(item));
      }*/
      //console.log("submit edit mediaData",this.mediaData);

      /*if ((this.uploadFiles.length > 0 && !this.copyId && !this.id)
        || (this.copyId && (this.images.length > 0 || this.uploadFiles.length > 0))
        || (this.id && (this.images.length > 0 || this.uploadFiles.length > 0))) {*/


      if ((this.postMedias.length > 0 && !this.copyId && !this.id)
        || (this.copyId && this.postMedias.length > 0)
        || (this.id && this.postMedias.length > 0)) {

        //postValue.append('media', JSON.stringify(this.mediaData));
        let postMedias = this.postMedias.filter(postMedia => {
          return !this.galleryMediaData.some(galleryMedia => galleryMedia.media_file === postMedia.media_file);
        });
        console.log("submit postMedias",postMedias)
        postValue.append('media', JSON.stringify(postMedias));
      }

      if (this.galleryMediaData.length > 0) {
        //for sorting
        let galleryMediaData = this.postMedias.filter(postMedia => {
          return this.galleryMediaData.some(galleryMedia => galleryMedia.media_file === postMedia.media_file);
        });
        console.log("submit galleryMediaData",galleryMediaData)
        postValue.append('gallery_media', JSON.stringify(galleryMediaData));
      }

      if (this.isFeedPost && this.images.length > 0) {
        postValue.append('feed_media', JSON.stringify(this.images));
      }

      if (this.postDate && this.postTime) {
        const time = moment(this.postTime).format('HH:mm');
        const date = moment(this.postDate).format('yyyy-MM-DD');
        postValue.append('post_date', date);
        postValue.append('post_time', time);
        postValue.append('timezone', Intl.DateTimeFormat().resolvedOptions().timeZone);
      }
      postValue.append('validation_type', this.accountProvider);
      postValue.append('all_account_provider', JSON.stringify(this.allAccountProvider));

      if (this.repeatPost) {
        postValue.append('repeat_post_data', JSON.stringify(data));
        postValue.append('timezone', Intl.DateTimeFormat().resolvedOptions().timeZone);
        postValue.append('repeat_post_type', this.repeatPostType);
        postValue.append('repeat_post_start_date', this.repeatPostStartDate.toString());
        postValue.append('repeat_post_end_date', this.repeatPostEndDate.toString());
      }

      if (
        this.postForm.value.first_comment !== '' &&
        this.postForm.value.first_comment !== null
        && this.postForm.value.first_comment !== undefined
      ) {
        postValue.append('first_comment', this.postForm.value.first_comment);
      }


      if ((this.allAccountProvider.includes(this.sharedService.youtubeProvider) ||
        this.allAccountProvider.includes(this.sharedService.pinterestProvider)) &&
        this.postForm.value.title !== '' && this.postForm.value.title !== null) {
        postValue.append('title', this.postForm.value.title);
      }

      // Youtube
      if (this.allAccountProvider.includes(this.sharedService.youtubeProvider) &&
        this.postForm.value.privacy !== '' && this.postForm.value.privacy !== null) {
        postValue.append('privacy', this.postForm.value.privacy);
      }

      if (this.allAccountProvider.includes(this.sharedService.youtubeProvider) &&
        this.postForm.value.audience !== '' && this.postForm.value.audience !== null) {
        postValue.append('audience', this.postForm.value.audience);
      }

      if (this.allAccountProvider.includes(this.sharedService.youtubeProvider) &&
        this.postForm.value.category !== '' && this.postForm.value.category !== null) {
        postValue.append('category', this.postForm.value.category);
      }

      if (this.allAccountProvider.includes(this.sharedService.youtubeProvider) &&
        this.postForm.value.youtube_video_tag != null && this.postForm.value.youtube_video_tag.length > 0) {
        postValue.append('youtube_video_tag', this.postForm.value.youtube_video_tag.join(','));
      }

      if (this.allAccountProvider.includes(this.sharedService.youtubeProvider) &&
        this.youtubeThumbnails?.length > 0) {
        postValue.append('youtube_thumbnail', this.youtubeThumbnails[0]);
      }

      // pinterest
      if (this.allAccountProvider.includes(this.sharedService.pinterestProvider) &&
        Object.keys(this.pinterestBoardIds).length !== 0) {
        postValue.append('pinterest_board_ids', JSON.stringify(this.pinterestBoardIds));
        postValue.append('pinterest_board_names', JSON.stringify(this.pinterestBoardNames));
      }

      if (this.allAccountProvider.includes(this.sharedService.pinterestProvider) &&
        this.postForm.value.pinterest_link !== '' && this.postForm.value.pinterest_link !== null) {
        postValue.append('pinterest_link', this.postForm.value.pinterest_link);
      }

      if (this.postForm.get('content').value !== null &&
        this.postForm.get('content').value.trimStart().length > 0) {

        const expression = /(https?:\/\/(?:www\.|(?!www))[^\s\.]+\.[^\s]{2,}|www\.[^\s]+\.[^\s]{2,})/gi;
        const matches = this.postForm.get('content').value.trimStart()
          .match(expression);

        if (matches !== null) {
          postValue.append('link', matches[matches.length - 1]);
        }
      }

      // instagram reels,story
      if (this.allAccountProvider.includes(this.sharedService.instagramProvider)) {
        postValue.append('instagram_type', this.postForm.value.instagram_type);

        if(this.postForm.value.instagram_type!=='story_post'){
          postValue.append('instagram_collaborators', JSON.stringify(this.postForm.value.instagram_collaborators));
        }
      }

      if (this.allAccountProvider.includes(this.sharedService.instagramProvider) &&
        this.instaReelThumbnails?.length > 0) {
        //postValue.append('youtube_thumbnail', this.youtubeThumbnails[0]);
        postValue.append('instagram_reel_thumbnail', this.instaReelThumbnails[0]);
      }

      postValue.append('is_instagram_standard_post', this.postForm.value.is_instagram_standard_post);
      // postValue.append('is_instagram_reels_post', this.postForm.value.is_instagram_reels_post);

      if (this.selectedLocation &&
          ( this.allAccountProvider.includes(this.sharedService.facebookProvider)
              || this.allAccountProvider.includes(this.sharedService.instagramProvider)) ) {
        //postValue.append('facebook_location', this.selectedLocation);
        let facebookLocation = { id:this.selectedLocation.id, name:this.selectedLocation.name};
        postValue.append('facebook_location', JSON.stringify(facebookLocation));
      }



      /* mentions */
      if (this.selectedFacebookMentions && this.allAccountProvider.includes(this.sharedService.facebookProvider)) {
          let facebookPagesData = this.selectedFacebookMentions.map((item) => ({ name: item.name, id: item.id }));
          postValue.append('facebook_mentions', JSON.stringify(facebookPagesData));
      }

      if (this.selectedInstagramMentions && this.allAccountProvider.includes(this.sharedService.instagramProvider)) {
          postValue.append('instagram_mentions', JSON.stringify(this.selectedInstagramMentions));
      }

      if (this.selectedTwitterMentions && this.allAccountProvider.includes(this.sharedService.twitterProvider)) {
        postValue.append('twitter_mentions', JSON.stringify(this.selectedTwitterMentions));
      }

      if (this.selectedThreadsMentions && this.allAccountProvider.includes(this.sharedService.threadsProvider)) {
        postValue.append('threads_mentions', JSON.stringify(this.selectedThreadsMentions));
      }

      if (this.selectedTiktokMentions && this.allAccountProvider.includes(this.sharedService.tiktokProvider)) {
        postValue.append('tiktok_mentions', JSON.stringify(this.selectedTiktokMentions));
      }


      if (this.id) {
        let images = this.postMedias.map((media) => media.media_file);
        let indexPostValue = {
          images : images,
          id : this.id,
        }
        console.log('indexPostValue: ' + indexPostValue);

        this.socialAccountService.editPostIndex(indexPostValue).subscribe({
          next: (response) => {
            console.log("success", response);
        
            this.socialAccountService.updatePost(this.id, postValue).subscribe({
              next: (res) => {
                this.isLoading = false;

                /********************/
               
                /********************/
        
                if (res.code === 200) {
                  this.postSuccess = res.data.success ;                                
                  this.postFailed =  res.data.failed;               
                  this.platformPostStatus = res.data.platform;
          
                  this.postAccountStatus = [];
                  this.postSuccess.forEach((item) => {
                    this.postAccountStatus[item.id] = { status: true, message: '' };
                  });
          
                  this.postFailed.forEach((item) => {
                    this.postAccountStatus[item.id] = { status: false, message: item.errors[0] };
                  });
                  // this.tempMediaData = [];
        
                  /*if (res.toast) {
                    this.sharedService.displayNotification(this.sharedService.messageTypeSuccess, this.sharedService.defaultMessageSuccess, res.message);
                  }*/
        
                  /*if (this.postStatus === this.sharedService.schedule) {
                    localStorage.setItem('page', this.page);
                    this.router.navigate(['/post/manage-post'], {
                      queryParams: { post: 'scheduled-post' },
                    });
                  } else if (data != null && data.submit_type != null && data.submit_type === 'queue') {
                    this.router.navigate(['/post/manage-post'], {
                      queryParams: { post: 'queue-post' },
                    });
                  } else {
                    this.router.navigate(['/post/manage-post'], {
                      queryParams: { post: 'draft-post' },
                    });
                  }*/
        
                } else {
                  this.repeatPost = false;
                  /*if(this.tempMediaData.length>0){
                    this.mediaData = [...this.tempMediaData, ...this.mediaData];
                  }*/
                }
        
                if (res.code === 500 && res.toast === true) {
                  this.isLoading = false;
                  /*if (res.message instanceof Object) {
                    for (const message of res.message) {
                      this.sharedService.displayNotification(this.sharedService.messageTypeError, this.sharedService.defaultMessageError, message);
                    }
                  } else {
                    this.sharedService.displayNotification(this.sharedService.messageTypeError, this.sharedService.defaultMessageError, res.message);
                  }*/
                }
        
                // validation errors
                if (res.code === 501 && res.toast === true) {
                  this.isLoading = false;
                  this.repeatPost = false;
                  this.postDate = null;
                  this.postTime = null;
                  this.validationErrors = res.data;

                  this.platformPostStatus = {};
                  this.postAccountStatus = [];
                  this.validationErrors.forEach((item)=>{
                  this.platformPostStatus[item.type] = {failed:1};
                  this.postAccountStatus[item.id] = { status:false, message:''}
              });
                  /*this.sharedService.displayNotification(this.sharedService.messageTypeError, this.sharedService.defaultMessageError, res.message);
                  this.validationErrors = res.data;*/
                }
        
              },
              error: (error) => {
                this.isLoading = false;
                this.repeatPost = false;
                console.log("update error: ", [error]);
                /*if(this.tempMediaData.length>0){
                  this.mediaData = [...this.tempMediaData, ...this.mediaData];
                }*/
              }
            });
          },
          error: (error) => {
            console.error("editPostIndex error: ", error);
          }
        });
      } else {
        this.validationErrors = [];
        this.postFailed = [];
        this.postSuccess = [];
        this.socialAccountService.createPost(postValue).subscribe(
          (res) => {
            this.sharedService.loadingText = '';
            //this.spinnerService.hide();
            this.changeDet.detectChanges();

            if (res.code === 200) {
              if (res.toast) {
                //this.sharedService.displayNotification(this.sharedService.messageTypeSuccess, this.sharedService.defaultMessageSuccess, res.message);
              }


              this.isLoading = false;
              //this.isPostingModal = false;
              //this.postForm.reset();

              /********************/
              this.postSuccess  = res.data.success;
              this.postFailed  = res.data.failed;
              this.platformPostStatus  = res.data.platform;

              this.postAccountStatus = [];
              this.postSuccess.forEach((item)=>{
                this.postAccountStatus[item.id] = { status:true, message:''}
              });

              this.postFailed.forEach((item)=>{
                this.postAccountStatus[item.id] = { status:false, message:item.errors[0]}
              });
              console.log("submit postFailed",this.postFailed)
              /********************/


              if (this.postDate === null && this.postTime === null
                && !this.repeatPost && this.selectedAssigneeUsers.length === 0) {


                /*if (data != null && data.submit_type != null && data.submit_type === 'queue') {
                  this.router.navigate(['/post/manage-post'], {
                    queryParams: { post: 'queue-post' },
                  });
                } else if (data != null && data.submit_type != null && data.submit_type === 'draft') {
                  this.router.navigate(['/post/manage-post'], {
                    queryParams: { post: 'draft-post' },
                  });
                } else {
                  this.router.navigate(['/post/manage-post'], {
                    queryParams: { post: 'delivered-post' },
                  });
                }*/

              } else if (this.selectedAssigneeUsers.length > 0) {
                /*this.router.navigate(['/post/manage-post'], {
                  queryParams: { post: 'draft-post' },
                });*/
              } else {
                /*this.router.navigate(['/post/manage-post'], {
                  queryParams: { post: 'schedule-post' },
                });*/
              }

            }

            if (res.code === 500 && res.toast === true) {
              this.postDate = null;
              this.postTime = null;
              if (!this.repeatPost) {
                this.isLoading = false;
                if (res.message instanceof Object) {
                  for (const message of res.message) {
                    this.sharedService.displayNotification(this.sharedService.messageTypeError, this.sharedService.defaultMessageError, message);
                  }
                } else {
                  if (res.data.type !== undefined &&
                    (res.data.type === 'facebook' || res.data.type === 'instagram')) {
                    this.errorProvider = res.data.type;
                    this.errorAccount = {
                      name: res.data.name,
                      id: res.data.id,
                      social_id: res.data.social_id
                    };

                    this.showErrorPopup = true;
                  }
                }

                this.isLoading = false;
                let message = res.message;
                if (res.data.type !== undefined) {
                  message = res.data.type + ' : ' + res.message;
                  this.sharedService.displayNotification(this.sharedService.messageTypeError, this.sharedService.defaultMessageError, message);
                }


                // Remove Selected Accounts which has posted already
                if (res.data.hasOwnProperty('already_posted') && res.data.already_posted.length > 0) {
                  this.isLoading = false;
                  const already_posted_account_id = [];
                  const already_posted_platforms = [];

                  res.data.already_posted.forEach((item) => {
                    already_posted_account_id.push(item.id);

                    if (!already_posted_platforms.includes(item.type_and_name)) {
                      already_posted_platforms.push(item.type_and_name);
                    }

                  });

                  this.selectedAccounts = this.selectedAccounts.filter((item) =>
                    !already_posted_account_id.includes(item.id)
                  );

                  this.postForm.patchValue({
                    accounts_id: this.selectedAccounts,
                  });
                  this.sharedService.displayNotification(this.sharedService.messageTypeSuccess, this.sharedService.defaultMessageSuccess, 'Post Successfully for these accounts : ' + already_posted_platforms.join(', '));
                }
              } else {

                // Repeat Post
                this.isLoading = false;
                this.repeatPost = false;

                if (res.message instanceof Object) {
                  for (const key in res.message) {
                    this.sharedService.displayNotification(this.sharedService.messageTypeError, this.sharedService.defaultMessageError, `${key}: ${res.message[key]}`);
                  }
                } else {
                  //this.sharedService.displayNotification(this.sharedService.messageTypeError, this.sharedService.defaultMessageError, res.message);
                  this.postResponseError = res.message;
                }
              }
            }

            // validation errors
            if (res.code === 501 && res.toast === true) {
              this.isLoading = false;
              this.repeatPost = false;
              this.postDate = null;
              this.postTime = null;
              //this.sharedService.displayNotification(this.sharedService.messageTypeError, this.sharedService.defaultMessageError, res.message);
              this.validationErrors = res.data;

              this.platformPostStatus = {};
              this.postAccountStatus = [];
              this.validationErrors.forEach((item)=>{
                this.platformPostStatus[item.type] = {failed:1};
                this.postAccountStatus[item.id] = { status:false, message:''}
              });

            }

          },
          (error) => {
            console.log("Submit Error : ", error);
            this.isLoading = false;
            this.repeatPost = false;
            this.changeDet.detectChanges();
          }
        );
      }
    }
  }

  back(): void {
    this.location.back();
  }

  youtubeTitleLength = (control: FormControl): { [s: string]: boolean | string } => {
    if (control.value !== null && this.allAccountProvider.includes(this.sharedService.youtubeProvider) &&
      control.value.trimStart().length > this.sharedService.getTitleLength(this.sharedService.youtubeProvider)) {
      return {
        youtubeTitleMaxlength: 'Max limit of YouTube title is ' +
          this.sharedService.getTitleLength(this.sharedService.youtubeProvider) + ' characters',
      };
    }
    return {};
  }

  pinterestTitleLength = (control: FormControl): { [s: string]: boolean | string } => {
    if (control.value !== null && this.allAccountProvider.includes(this.sharedService.pinterestProvider) &&
      control.value.trimStart().length > this.sharedService.getTitleLength(this.sharedService.pinterestProvider)
    ) {
      return {
        pinterestTitleMaxlength: 'Max limit of Pinterest title is ' +
          this.sharedService.getTitleLength(this.sharedService.pinterestProvider) + ' characters',
      };
    }
    return {};
  }

  getYoutubeCategories() {

    this.youtubeService.getYoutubeCategories().subscribe(
      (res) => {
        if (res.code === 200) {
          this.youtubeCategories = [...res.data];
        }
      });
  }

  profilePic(): string {
    console.log("profilePic")
    return this.selectedAccounts[0].image;
  }

  username(): string {
    //console.log("username")
    return this.selectedAccounts[0].name;
  }

  modalClose: any;

  openUploadModel(): void {

    console.log(this.isEditImage,"this.isEditImage");


    if (this.allAccountProvider.length === 0) {
      this.sharedService.displayNotification(this.sharedService.messageTypeInfo, this.sharedService.defaultMessageError, 'Please select an account before creating any post');
      return;
    }

    if(this.postMedias.length >= 10 && !this.isEditImage) {
      console.log("openUploadModel");
      this.sharedService.displayNotification(this.sharedService.messageTypeInfo, this.sharedService.defaultMessageError, "You cannot selected more than 10 images");
      return;
    }

    this.validationErrors = [];
    this.postFailed = [];
    this.closeCropModal = this.modal.create({
      nzTitle: this.isDirectUpload ? 'Upload' : 'Library Media',
      nzContent: GalleryPreviewComponent,
      nzCentered: true,
      nzMaskClosable: false,
      nzClassName: this.isDirectMultiUpload || !this.isDirectUpload ? 'upload-modal-create-post gallery-upload' : 'upload-modal-create-post',
      nzClosable: false,
      nzStyle: { top: '20px' },
      nzComponentParams: {
        page: 'create_post',
        images: [...this.images],
        maxLength: this.maxLength,
        type: this.type,
        deletedPhotos: this.deletedPhotos,
        provider: this.provider,
        accountProvider: this.accountProvider,
        allAccountProvider: this.allAccountProvider,
        //imageFile: [...this.uploadFiles],
        //mediaData: [...this.mediaData],
        postMedias: this.postMedias,
        directMultipleUpload : this.directMultipleUpload,
        isDirectMultiUpload : this.isDirectMultiUpload,
        tempPost: false,
        galleryMediaData: [...this.galleryMediaData],
        cropTool: this.cropTool,
        isDirectUpload: this.isDirectUpload,
        selectedImage: this.selectedGalleryFile,
        selectedImageURL: this.selectedImageURL,
        isEditImage: this.isEditImage,
        editImageIndex: this.editImageIndex,
      },
      nzFooter: [
        {
          label: 'Cancel',
          onClick: () => {
            const instance = this.closeCropModal.getContentComponent();
            console.log("gallery instance on cancel:",instance)
            if (!instance.loading) {
              this.isDirectMultiUpload = false;
              this.directMultipleUpload = [];
              instance.imageFile = this.uploadFiles;
              instance.images = this.images;
              //instance.mediaData = this.mediaData;
              instance.postMedias = this.postMedias;
              instance.cancelWithoutUpload();
              this.resetCropping();
              this.closeCropModal.destroy();
            }
          },
        },
        {
          label: 'Ok',
          type: 'primary',
          onClick: () => {
            console.log("Before postMedias",this.postMedias);
            const instance = this.closeCropModal.getContentComponent();
            if (!instance.loading) {
              console.log("Before this.postMedias",this.postMedias);
              this.isDirectMultiUpload = false;
              this.directMultipleUpload = [];
              console.log("gallery instance:",instance)
              //this.uploadFiles = [...instance.imageFile];
              this.images = [...instance.images];
              this.type = instance.type;
              this.error = instance.error;
              this.maxLength = instance.maxLength;
              this.deletedPhotos = instance.deletedPhotos;
              //this.mediaData = [...instance.mediaData];
              this.postMedias = [...instance.postMedias];
              console.log("After postMedias",this.postMedias);
              console.log("---->instance selectedGalleryFiles",instance.selectedGalleryFiles)
              if (instance.canSelectMediaFromGallery === true
                && instance.selectedGalleryFiles) {

                /*if (this.type === 'video') {
                  this.galleryMediaData = [];
                }*/
                console.log("inside --> selectedGalleryFiles")
                instance?.selectedGalleryFiles.map((media)=>{
                  console.log("insiode --> map  media",media.media_file)
                  const fileMeta = JSON.parse(media.file_metadata)
                  this.images.push(media.media_file);
                  this.galleryMediaData.push({
                    id: media._id,
                    media_file: media.media_file,
                    file_type: media.file_type,
                    file_mime_type: fileMeta.file_mime_type,
                  });
                  this.postMedias.push({
                    id: media._id,
                    media_file: media.media_file,
                    file_type: media.file_type,
                  });
                  // For Image height and width validation
                  if(this.accountProvider.includes(this.sharedService.instagramProvider)){
                    if(media.file_type == "image" && fileMeta.file_mime_type != "image/gif"){
                      const { errors, mediaData: updatedMediaData } = this.sharedService.validateImageRatios(fileMeta.height,fileMeta.width,media.media_file,this.postMedias);
                      if (errors.length > 0) {
                          this.uploadImgError = this.uploadImgError.concat(errors);
                      }
                      this.postMedias = updatedMediaData
                    }
                  }
                });
                console.log("instance images : ",this.images)
                console.log("instance postMedias : ",this.postMedias)
              }else{
                console.log("selected media loading from gallery")
                return false;
              }


              console.log("this.uploadImgError",this.uploadImgError);
              this.uploadImgError = this.uploadImgError.filter((imgError) => {
                return this.postMedias.some((media) => media.media_file === imgError.fileName);
              });

              // delete gallery medias
              this.deletedGalleryMedias = instance.deletedGalleryMedias;
              if (instance.deletedGalleryMedias.length > 0) {
                this.galleryMediaData = this.galleryMediaData.filter((item) =>
                  !instance.deletedGalleryMedias.includes(item.media_file)
                );
              }

              /* if(this.accountProvider.includes(this.sharedService.instagramProvider)){
                instance.selectedGalleryFiles.map((media)=> {
                  if(media.file_type == "image"){
                    const { errors, mediaData: updatedMediaData } = this.sharedService.validateImageRatios(media.meta.height,media.meta.width,media.media_file,this.postMedias);
                    if (errors.length > 0) {
                        this.uploadImgError = this.uploadImgError.concat(errors);
                    }
                    this.postMedias = updatedMediaData
                  }
              });
              } */
              //this.multiImages = this.mediaData.concat(this.galleryMediaData);
              this.resetCropping();
              this.closeCropModal.destroy();
              }
          },
        },
      ],
    });



    this.modal.afterAllClose.subscribe(() => {
      this.closeCropModal.close();
      this.closeCropModal.destroy();
      this.resetCropping();
    });

    this.closeCropModal.afterClose.subscribe(() => {
      // window.scrollTo(0, 0);
    });


  }

  resetCropping() {
    this.cropTool = false;
    this.isDirectUpload = false;
    this.selectedGalleryFile = null;
    this.selectedImageURL = null;
    this.isEditImage = false;
    //check first comment for LinkedIn
    this.isCommentDisplay();
    this.setValidation();
  }

  deleteChange(event): void {
    console.log("post deleteChange : ",event);
    //console.log("deleteChange deletedPhotos: ",this.deletedPhotos);
    this.type = event.type;
    this.maxLength = event.maxLength;
    this.deletedPhotos = [...this.deletedPhotos, ...event.deletedPhotos];
    //console.log("deleteChange deletedPhotos2: ",this.deletedPhotos);
    //this.mediaData = event.mediaData;
    this.postMedias = event.postMedias;
    this.galleryMediaData = event.galleryMediaData;
    this.deletedGalleryMedias = event.deletedGalleryMedias;
    if(event.youtubeThumbnails){
      this.youtubeThumbnails = event.youtubeThumbnails;
    }
    if(event.instaReelThumbnails){
      this.instaReelThumbnails = event.instaReelThumbnails;
    }
    this.validationErrors = [];
    this.error = '';
    this.isCommentDisplay();


    //this.multiImages = this.mediaData.concat(this.galleryMediaData);

    console.log("deleteChange postMedias: ",this.postMedias);
    console.log("Before Filtered Upload Errors: ",this.uploadImgError);
    this.uploadImgError = this.uploadImgError.filter((imgError) => {
      return this.postMedias.some((media) => media.media_file === imgError.fileName);
    });

    // instagram reels
    /*this.postForm.get('instagram_type').setValue('feed_post');
    this.postForm.get('is_instagram_standard_post').setValue('false');*/
  }

  // async editImageChanges(event): Promise<void> {
  //   this.cropTool = true;
  //   var imageIndex = event.index;
  //   const rand = '&rand=' + Math.random();
  //   var pattern = /^((http|https|ftp):\/\/)/;

  //   if (this.isFeedPost && pattern.test(this.images[imageIndex])) {
  //     this.selectedImageURL = this.images[imageIndex];
  //   } else {
  //     //this.selectedImageURL = await this.indexDbService.getPresignedImageUrl(this.images[imageIndex]) + rand;
  //     this.selectedImageURL = await this.indexDbService.getPresignedImageUrl(this.images[imageIndex]);
  //   }
  //   this.isEditImage = true;
  //   this.editImageIndex = imageIndex;
  //   console.log(this.editImageIndex,"this.editImageIndex editImageChanges");

  //   this.openUploadModel();
  // }

  async editImageChanges(event): Promise<void> {
    this.cropTool = true;
    var imageIndex = event.index;
    const rand = '&rand=' + Math.random();
    var pattern = /^((http|https|ftp):\/\/)/;

    if (this.isFeedPost && pattern.test(this.postMedias[imageIndex].media_file)) {
      this.selectedImageURL = this.postMedias[imageIndex].media_file;
    } else {
      //this.selectedImageURL = await this.indexDbService.getPresignedImageUrl(this.images[imageIndex]) + rand;
      this.selectedImageURL = await this.indexDbService.getPresignedImageUrl(this.postMedias[imageIndex].media_file);
    }
    this.isEditImage = true;
    this.isDirectUpload = true;
    this.editImageIndex = imageIndex;
    this.openUploadModel();
  }

  fileExistsInUploading(fileName): boolean {
    return this.currentUploading.some(el => {
      return el.file_name === fileName;
    });
  }

  //direct upload
  beforeUpload = (file: NzUploadFile, _fileList: NzUploadFile[]): boolean => {
    document.body.classList.remove('openNzSelect');
    this.validationErrors = [];
    this.postFailed = [];
    this.setCropTool();

    /*if (this.images.length == 1 && this.type == "video") {
      this.sharedService.displayNotification(this.sharedService.messageTypeError, 'Media files', 'You can upload Images/GIFs or video at a time if you want to upload video remove the other media file');
      return false;
    }*/

    // if (this.images.length >= this.maxLength) {
    console.log(this.postMedias.length + _fileList.length,"this.postMedias.length + _fileList.length");
    console.log(this.postMedias.length,"this.postMedias.length");
    console.log(_fileList.length,"_fileList.length");
    console.log(_fileList,"_fileList");



    if (this.postMedias.length + _fileList.length > this.maxLength) {
      this.sharedService.displayNotification(this.sharedService.messageTypeError, 'Media files', 'You can not upload more than ' + this.maxLength + ' Media Files!');
      return false;
    }




    this.error = null;
    console.log("beforeUpload file",file)
    console.log("beforeUpload type",this.type)
    //console.log("beforeUpload _fileList",_fileList)
    if (this.supportedFileTypes.includes(file.type)) {

      const supportedImageTypes: any = ['image/jpg', 'image/jpeg', 'image/png'];
      const supportedVideoTypes: any = ['video/mov', 'video/quicktime', 'video/mp4', 'video/m4v'];

      if (supportedImageTypes.includes(file.type)) {
        /* if (this.images.length > 0 && this.type !=='image') {
          this.type = 'multi';
        }else{
          this.type = 'image';
        } */

        if (file.size > (5 * 1024 * 1024) || file.size < 2 * 1024) {
          this.loading = false;
          this.error = 'Image size should be between 2KB and 5MB';
          return false;
        }

        // For Instagram Crop
        if (_fileList.length >= 1 && this.cropTool) {
          this.selectedGalleryFile = file;
          console.log("this.selectedGalleryFile",this.selectedGalleryFile);
          this.isDirectUpload = true;
          console.log("Before called isDirectMultiUpload",this.isDirectMultiUpload);

          if(_fileList.length > 1 && !this.isDirectMultiUpload){
            console.log("If condition called ........");
            this.isDirectMultiUpload = true;
            this.directMultipleUpload = _fileList.filter(file => !supportedVideoTypes.includes(file.type));
            // this.directMultipleUpload = _fileList;
            this.openUploadModel();
          }
          if(_fileList.length == 1){
            console.log("Else condition called ........");
            // this.isEditImage = true;
            this.isDirectMultiUpload = false;
            this.openUploadModel();
          }
          return false;
        }
      } else if (file.type === 'image/gif') {
        // this.type = 'gif';
        if (file.size < 5 * 1024) {
          this.loading = false;
          this.error = 'Gif size should not be less then 5 kb';
          return false;
        }
        /* if (this.images.length > 0 && this.type !=='gif') {
          this.type = 'multi';
        }else{
          this.type = 'gif';
        } */
      } else if (supportedVideoTypes.includes(file.type)) {

        /*if (this.images.length > 0) {
          this.error = 'You can upload Images/GIFs or video at a time if you want to upload video remove the other media file';
          return true;
        }*/

        //if (this.images.length > 0 && this.type !=='video') {

        console.log("supportedVideoTypes images.length",this.images.length);
        //if (this.images.length > 0 || _fileList.length>0) {
        /* if (this.images.length > 1) {
          this.type = 'multi';
        }else{
          this.type = 'video';
        } */

        console.log("supportedVideoTypes type",this.type);
        //this.maxLength = 1;
        this.isVideoUploaded = true;
      }


      const size = file.size;
      const fileMimeType = file.type;
      const type = file.type.split('/')[0];

      let ext = file.name.substring(file.name.lastIndexOf(".") + 1);
      let folder = 'post/user-' + this.store.selectSnapshot(AuthState.user).id + '/';
      let fileName = moment().unix() + '_' + Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5) + '.' + ext;
      let fileMeta = { file_size: size, file_type: type, file_mime_type: fileMimeType, media_file: folder + fileName };

      this.loading = true;
      if (!this.fileExistsInUploading(fileName)) {
        this.currentUploading.push({ file_name: fileName, percent: 0, loading: true });
      }

      var index = 0;
      var currentPercent = 0;
      var intervalArray = [];
      let data = false;
      this.wasabiService.uploadPostMedia(file, folder, fileName)
        .subscribe((event: HttpEvent<any>) => {
          console.log("uploadMediaHTTP event.type:",event.type)
          switch (event.type) {
            case HttpEventType.Sent:
              break;
            case HttpEventType.ResponseHeader:
              break;
            case HttpEventType.UploadProgress:
              console.log("UploadProgress currentUploading",[this.currentUploading]);
              index = this.currentUploading.findIndex(x => x.file_name === fileName);
              //console.log("HttpEventType.UploadProgress index:",index, fileName)
              if (index >= 0) {
                if (intervalArray[index]) {
                  clearInterval(intervalArray[index]);
                }

                intervalArray[index] = setInterval(() => {
                  console.log("Post storeFile interval");
                  // get realtime index
                  index = this.currentUploading.findIndex(x => x.file_name === fileName);
                  //console.log("HttpEventType.UploadProgress index 2:",index, fileName)
                  if (currentPercent !== 99) {
                    currentPercent++;
                    if (this.currentUploading[index]) {
                      this.currentUploading[index].percent = currentPercent;
                      /*let uploadPercent = Math.round((event.loaded / event.total) * 100);
                      console.log("uploadPercent : ",uploadPercent)
                      console.log("uploadPercent loaded: ",event.loaded)
                      console.log("uploadPercent total: ",event.total)
                      this.currentUploading[index].percent = uploadPercent;*/
                    }
                  }
                }, 1000);
              }
              break;
            case HttpEventType.DownloadProgress:
              if (this.currentUploading[index]) {
                this.currentUploading[index].percent = 100;
                clearInterval(intervalArray[index]);
              }
              break;
            case HttpEventType.Response:
              console.log("upload event HttpEventType.Response :",HttpEventType.Response)
              if (this.currentUploading[index]) {
                this.currentUploading[index].percent = 100;
              }

              // setTimeout to display media is uploaded
              setTimeout(() => {
                data = true;
                if (data) {
                    let media_file = folder + fileName;
                    //this.uploadFiles.push(file);
                    this.images.push(media_file);
                    //this.mediaData.push(fileMeta);
                    //this.fileMetaDatas.push(JSON.stringify(fileMeta));
                    //this.fileTypes.push(type);
                    //this.mediaFiles.push(media_file);
                    this.postMedias.push(fileMeta);
                    this.changeDet.detectChanges();
                    //check first comment for LinkedIn
                    this.isCommentDisplay();

                    // delete from uploading list
                    index = this.currentUploading.findIndex(x => x.file_name === fileName);

                    this.currentUploading[index].loading = false;
                    this.currentUploading.splice(index, 1);

                    console.log("Multiple upload called",this.currentUploading.length);
                    if(supportedImageTypes.includes(file.type)){
                      this.sharedService.getImageDimensions(file).then(dimensions => {
                        console.log('Image Height Width:',dimensions.height, dimensions.width);

                        const imageWidth = dimensions.width;
                        const imageHeight = dimensions.height;
                        /* this.postMedias.forEach((fi, idx) => {
                          let isValidMatch = fi.media_file === folder+fileName;
                          console.log("!fi.height and !fi.width",!fi.height,!fi.width);
                          if ((!fi.height || !fi.width) && isValidMatch) {
                            this.postMedias[idx] = { ...fi, width: imageWidth, height: imageHeight };
                          }
                        }); */
                        // Only For Instagram
                        if(this.accountProvider.includes(this.sharedService.instagramProvider)){
                          const { errors, mediaData: updatedMediaData } = this.sharedService.validateImageRatios(imageHeight,imageWidth,folder+fileName,this.postMedias);
                          if (errors.length > 0) {
                              this.uploadImgError = this.uploadImgError.concat(errors);
                          }
                          this.postMedias = updatedMediaData
                        }

                        console.log('Updated postMedias:', this.postMedias);

                      }, (error) => {
                          console.error('Error getting image dimensions:', error);
                      });
                    }

                    if (this.currentUploading.length === 0) {
                      this.loading = false;
                      for (var i = 1; i < this.images.length; i++) {
                        clearInterval(intervalArray[i]);
                      }
                      return false;
                    }
                }
              }, 1000);
          }
        }, error => {
          return false;
        });
      return false;
    } else {
      this.sharedService.displayNotification(this.sharedService.messageTypeError, 'Media files', 'We can\'t quite use that type of file. Could you try one of the following instead: JPG, JPEG, PNG, GIF, MOV, MP4, M4V?');
    }
    return false; // key to manual upload
  }

  //generated image from ai base64
  uploadGeneratedImages = () => {
    this.isImageGenerateModal = false

    this.validationErrors = [];
    this.postFailed = [];
    //this.setCropTool();
    if (this.images.length == 1 && this.type == "video") {
      this.sharedService.displayNotification(this.sharedService.messageTypeError, 'Media files', 'You can upload Images/GIFs or video at a time if you want to upload video remove the other media file');
      return false;
    }
    // if (this.images.length >= this.maxLength) {
    if (this.postMedias.length >= this.maxLength) {
      this.sharedService.displayNotification(this.sharedService.messageTypeError, 'Media files', 'You can not upload more than ' + this.maxLength + ' Media Files!');
      return false;
    }

    this.error = null;
    this.selectedGeneratedImage.forEach((item, imgIndex) => {

      //generate file
      //const base64String = this.generatedImages[0]; // your base64 string
      const base64String = item; // your base64 string
      //console.log("uploadGeneratedImages base64String", base64String)
      const byteString = atob(base64String);
      const arrayBuffer = new ArrayBuffer(byteString.length);
      const uint8Array = new Uint8Array(arrayBuffer);

      /*console.log("uploadGeneratedImages byteString", byteString);
      console.log("uploadGeneratedImages arrayBuffer", arrayBuffer);
      console.log("uploadGeneratedImages uint8Array", uint8Array);*/

      for (let i = 0; i < byteString.length; i++) {
        uint8Array[i] = byteString.charCodeAt(i);
      }

      //console.log("uploadGeneratedImages uint8Array 2", uint8Array);

      const blob = new Blob([uint8Array], {type: 'image/jpeg'});
      console.log("uploadGeneratedImages blob", blob);

      const file = new File([blob], 'image.jpg', {
        lastModified: Date.now(),
        type: "image/jpeg",
      });
      console.log("uploadGeneratedImages file", file);
      //generate file


      this.type = 'image';
      /*if (file.size > (5 * 1024 * 1024) || file.size < 2 * 1024) {
        this.loading = false;
        this.error = 'Image size should be between 2KB and 5MB';
        return false;
      }*/

      // For Instagram Crop
      /*if (this.cropTool) {
        this.selectedGalleryFile = file;
        this.isDirectUpload = true;
        this.openUploadModel();
        return false;
      }*/

      const size = file.size;
      const fileMimeType = file.type;
      const type = file.type.split('/')[0];

      let ext = file.name.substring(file.name.lastIndexOf(".") + 1);
      let folder = 'post/user-' + this.store.selectSnapshot(AuthState.user).id + '/';
      let fileName = moment().unix() + '_' + Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5) + '.' + ext;
      let fileMeta = {file_size: size, file_type: type, file_mime_type: fileMimeType, media_file: folder + fileName};

      this.loading = true;
      if (!this.fileExistsInUploading(fileName)) {
        this.currentUploading.push({file_name: fileName, percent: 0, loading: true});
      }

      var index = 0;
      var currentPercent = 0;
      var intervalArray = [];
      let data = false;
      this.wasabiService.uploadPostMedia(file, folder, fileName)
          .subscribe((event: HttpEvent<any>) => {
            console.log("uploadMediaHTTP event.type:", event.type)
            switch (event.type) {
              case HttpEventType.Sent:
                break;
              case HttpEventType.ResponseHeader:
                break;
              case HttpEventType.UploadProgress:
                index = this.currentUploading.findIndex(x => x.file_name === fileName);
                //console.log("HttpEventType.UploadProgress index:",index, fileName)
                if (index >= 0) {

                  intervalArray[index] = setInterval(() => {
                    // get realtime index
                    index = this.currentUploading.findIndex(x => x.file_name === fileName);
                    //console.log("HttpEventType.UploadProgress index 2:",index, fileName)
                    if (currentPercent !== 99) {
                      currentPercent++;
                      if (this.currentUploading[index]) {
                        this.currentUploading[index].percent = currentPercent;
                      }
                    }
                  }, 1000);
                }
                break;
              case HttpEventType.DownloadProgress:
                if (this.currentUploading[index]) {
                  this.currentUploading[index].percent = 100;
                  clearInterval(intervalArray[index]);
                }
                break;
              case HttpEventType.Response:
                console.log("upload event HttpEventType.Response :", HttpEventType.Response)
                if (this.currentUploading[index]) {
                  this.currentUploading[index].percent = 100;
                }

                // setTimeout to display media is uploaded
                setTimeout(() => {
                  data = true;
                  if (data) {

                    let media_file = folder + fileName;
                    //this.uploadFiles.push(file);
                    this.images.push(media_file);
                    //this.mediaData.push(fileMeta);
                    //this.fileMetaDatas.push(JSON.stringify(fileMeta));
                    //this.fileTypes.push(type);
                    //this.mediaFiles.push(media_file);
                    this.postMedias.push(fileMeta);
                    //check first comment for LinkedIn
                    // this.isCommentDisplay();

                    // delete from uploading list
                    index = this.currentUploading.findIndex(x => x.file_name === fileName);

                    this.currentUploading[index].loading = false;
                    this.currentUploading.splice(index, 1);

                    if (this.currentUploading.length === 0) {
                      this.loading = false;
                      for (var i = 1; i < this.images.length; i++) {
                        clearInterval(intervalArray[i]);
                      }
                      //this.multiImages = this.mediaData.concat(this.galleryMediaData);
                      return false;
                    }
                  }
                }, 1000);
            }
          }, error => {

          });
    });

    this.textToImage = '';
    this.generatedImages = [];
    this.selectedGeneratedImage = [];

  }





  underDevelopment() {
    this.sharedService.displayNotification(this.sharedService.messageTypeError, this.sharedService.defaultMessageError, 'Under development!');
  }


  setCropTool() {
    if (this.allAccountProvider.includes(this.sharedService.instagramProvider)) {
      this.cropTool = true;
    } else {
      this.cropTool = false;
    }
  }

  /* handleShowErrorPopupCancel(id): void {
    this.showErrorPopup = false;
    this.errorProvider = null;
    this.errorAccount = {};
    this.selectedAccounts = this.selectedAccounts.filter((item) => item.id !== id);
    this.postForm.patchValue({ accounts_id: this.selectedAccounts });
  } */


  suggestion() {
    this.tabType = 'suggestion';
    var addHashToTemplate = this.selectedHashList.filter(opt => opt.checked).map(opt => " #" + opt.name).toString();
    this.templateForm.patchValue({
      template_description: addHashToTemplate
    });
  }


  onKeyUp(val: any, page: Number) {
    this.searchText = val;
    const paramss = { 'search': this.searchText, 'page': page };
    //console.log("onKeyUp paramss: ",paramss);

    //if (paramss.search == '') {
    this.hashList = [];
    this.allHash = [];
    this.searchResult = [];
    this.searchResult.users = [];
    this.selectedHashList = [];
    this.selectedHash = 0
    // return false;

    //this.emptySearchLoading = true;
    //}

    if (this.searchText != '') {
      this.isSpinning = true;
    }

    this.subject.next(paramss);
    // this.isLoadingHas = false;
  }

  emptyData;
  onSearchEvent(val, page: Number) {
    this.accounts.forEach(ele => {
      if (ele.provider == 'TWITTER') {
        this.userSocialId = ele.id
      }
    })

    const paramss = { 'social_connection_id': this.userSocialId, 'search': this.searchText, 'page': page };

    if (this.searchText != '') {
      this.emptySearchLoading = false;

      this.isSpinning = true;
      this.isLoadingHas = true;


      this.destroySearch = this.twitterService.getSearchUsers(paramss).subscribe((response) => {
        this.isSpinning = false;
        this.isLoadingHas = false;

        if (response.code === 200 && response.data) {

          if (response.data.users && response.data.users.length < 20) {
            this.loadingMore = false;
          } else {
            this.loadingMore = true;
          }
          if (page > 1) {

            this.searchResult.users = [...this.searchResult, ...response.data.users];

            this.searchResult.users.forEach(ele => {
              this.hashList.push({ name: ele.screen_name, checked: false });
            })
          } else {
            this.searchResult = response.data.users;
            this.searchResult.forEach(ele => {
              this.hashList.push({ name: ele.screen_name, checked: false });
            })
          }
          this.isLoadingHas = this.hashList.length > 0 ? true : false;
          this.emptyData = this.hashList.length == 0 ? true : false;
        } else {
          if (response.code) {

          }
        }

      });
    }
    if (this.searchText == '') {
      this.allHash = [];
      this.hashList = [];
      this.searchResult = [];
      this.selectedHashList = [];
      this.selectedHash = 0;
      this.emptySearchLoading = true;
    }
  }

  SingleCheckedHash() {

    let data: any = [];
    this.hashList.forEach(function (opt: any) {
      if (opt.checked) {
        data.push(opt);
      }
    })
    this.selectedHash = data.length;
    this.selectedHashList = data;

  }

  deselectHash() {
    this.selectedHash = 0;
    this.hashList.forEach(function (opt: any) {
      if (opt.checked) {
        opt.checked = false;
      }
    })
    this.selectedHashList.length = 0;

  }

  addToPostDirect() {

    var checkedstatus = this.selectedHashList.filter(opt => opt.checked).map(opt => " #" + opt.name).toString().replace(/,/g, " ");
    if (this.postForm.get('content').value !== '') {
      var appendContent = this.postForm.get('content').value + " " + checkedstatus;
      this.postForm.get('content').setValue(appendContent);
    } else {
      this.postForm.controls.content.setValue(checkedstatus);
    }
    this.hashTag = false;
    this.selectedHash = 0
    this.setValidation();
  }

  hashTagDrawer(): void {
    this.getAllTemplate();
    this.hashTag = true;
    this.hashList = [];
    this.selectedHash = 0;
    this.selectedHashList = [];
    this.accounts.forEach(ele => {
      if (ele.provider == 'TWITTER') {
        this.userSocialId = ele.id
      }
    })
    if (this.userSocialId != '') {
      this.connectTwitter = true;
    }
    if (this.userSocialId == '') {
      this.connectTwitter = false;
    }
    this.searchText = '';
    this.allHash = [];
    this.searchResult = [];
    this.searchResult.users = [];
    this.emptySearchLoading = true;
  }
  close(): void {
    this.hashTag = false;
    this.templateForm.reset();
    this.templateForm.patchValue({
      template_name: null,
      template_description: null
    });
    this.modalTitle = 'Create New Template';
    this.buttonName = 'Save';
    // this.isSpinning = false;
    this.isLoadingHas = false;
    this.selectedHash = 0
    this.hashList = [];
    this.allHash = [];
    this.searchResult = [];
    this.searchResult.users = [];
    this.selectedHashList = [];
  }
  showModal(): void {
    this.isVisible = true;
    this.modalTitle = 'Create New Template';
    this.buttonName = 'Save';

    var addHashToTemplate = this.selectedHashList.filter(opt => opt.checked).map(opt => " #" + opt.name).toString().replace(/,/g, " ");
    if (this.tabType == 'suggestion') {
      this.templateForm.patchValue({
        template_description: addHashToTemplate
      });
    }
  }


  updateTemplate(event) {

    this.allTemplate.forEach(ele => {
      if (ele.name == event) {
        this.templateId = ele._id;
      }
    })
    // this.selectedValue = '';
  }

  updateTemplateData() {

    if (this.selectedValue == '') {
      this.sharedService.displayNotification(this.sharedService.messageTypeError, 'Template Select', 'Please select Template');
      return false;
    }

    var addHashToTemplate = this.selectedHashList.filter(opt => opt.checked).map(opt => " #" + opt.name).toString().replace(/,/g, " ");
    var postValue = {
      template_id: this.templateId,
      description: addHashToTemplate
    }
    this.isHashLoading = true;
    this.sharedService.updateTemplateData(postValue).subscribe((res: any) => {
      if (res.code === 200) {
        this.sharedService.displayNotification(this.sharedService.messageTypeSuccess, 'Suggestions', res.message);
        this.isHashLoading = false;
        // this.getAllTemplate();
        // this.templateForm.reset();
        // this.isHashLoading = false;
        this.isVisible = false;
        this.hashTag = false;
        this.isexistingTemp = false;
        this.selectedValue = '';
        this.selectedHash = 0
      }

    })

  }

  editModal(item: Object) {

    if (Object.keys(item).length > 0) {
      this.isVisible = true;

      this.modalTitle = "Edit Template"
      this.buttonName = "Apply Changes"

      this.editTemplateId = item['_id'];
      this.templateForm.patchValue({
        template_name: item['name'],
        template_description: item['content']

      });
    } else {
      this.isVisible = true;

      this.modalTitle = "Create New Template"
      this.buttonName = "Save"
    }

  }
  handleCancel(): void {
    this.editTemplateId = 0;
    this.isVisible = false;
    this.templateForm.reset();
  }
  existingTemp(): void {

    this.getAllTemplate();
    this.isexistingTemp = true;
  }
  existingTempCancel(): void {
    this.isexistingTemp = false;
  }


  getAllTemplate() {
    this.tabType = 'template'
    this.isHashLoading = true;
    this.sharedService.getAllTemplate().subscribe((res: any) => {
      if (res.code == 200) {
        this.isHashLoading = false;
        this.allTemplate = res.data;
      }
    })
  }



  SaveTemplate() {

    for (const i in this.templateForm.controls) {
      this.templateForm.controls[i].markAsDirty();
      this.templateForm.controls[i].updateValueAndValidity();
    }

    if (this.templateForm.valid) {
      const value = this.templateForm.value;
    }

    if (this.templateForm.valid) {
      const value = this.templateForm.value;

      const postValue: any = HelperService.createFormData(value);
      if (this.editTemplateId != 0) {
        postValue.append('_id', this.editTemplateId);
      }
      this.isHashLoading = true;
      this.sharedService.createTemplate(postValue).subscribe((res: any) => {

        if (res.code === 200) {
          this.sharedService.displayNotification(this.sharedService.messageTypeSuccess, 'Template', res.message);
          this.getAllTemplate();
          this.templateForm.reset();
          this.isHashLoading = false;
          this.isVisible = false;
          // this.hashTag = false
          this.selectedHash = 0
        } else {
          this.isVisible = true;
          this.isHashLoading = false;
          this.sharedService.displayNotification(this.sharedService.messageTypeError, 'Template', res?.message);
        }

      })
    }
    this.editTemplateId = 0;

  }

  AddToPost(content) {


    if (this.postForm.get('content').value !== '') {
      var appendContent = this.postForm.get('content').value + " " + content;
      this.postForm.get('content').setValue(appendContent);
    } else if (content != '') {
      this.postForm.get('content').setValue(content);
    }
    this.hashTag = false;
    this.selectedHash = 0;
  }

  deleteTemplate(templateId) {


    var postValue = {
      id: templateId
    }
    this.modal.confirm({
      nzTitle: 'Delete Template?',
      nzClassName: 'app_warning_modal_main',
      nzContent: AppWarningModalComponent,
      nzComponentParams: {
        message: "Are you sure you want to Delete Template?",
      },
      // nzOkText: this.isLoadingLogOut ? 'Log Outing...' : 'Yes, Log Out',
      nzOkType: 'primary',
      nzOkDanger: true,
      nzCentered: true,
      nzCancelText: 'Cancel',
      nzIconType: null,
      nzAutofocus: null,
      // nzOkDisabled: this.isLoadingLogOut,
      // nzCancelDisabled: this.isLoadingLogOut,
      nzOnOk: () => {

        this.sharedService.deleteTemplate(postValue).subscribe((res: any) => {


          if (res.code == 200) {
            this.sharedService.displayNotification(this.sharedService.messageTypeSuccess, "Template", res.message);
            this.templateContent = '';
            this.getAllTemplate();
          }

        });
      }
    });
  }
  nextBatch(e) {
    const end = this.viewport.getRenderedRange().end;
    const total = this.viewport.getDataLength();

    if (end == total) {

      if (this.isLoadingHas && this.loadingMore == true) {
        this.searchPage += 1;
        this.onKeyUp(this.searchText, this.searchPage);

      }
    }
  }


  prevent(event) {
    event.preventDefault();
  }

  async updateTeams(event) {
    this.selectedTeam = event;
    console.log("updateTeams event: ", event);
    console.log("updateTeams selected: ", this.selectedTeam);

    if (this.selectedTeam?.id) {
      localStorage.setItem('team_id', this.selectedTeam.id)
    }

    //if (!this.user.is_super_admin) {
    this.assigneeUsers = [];
    this.accounts = [];
    this.selectedAccounts = [];
    this.postForm.patchValue({ accounts_id: [] });
    await this.getActiveAccounts(this.selectedTeam?.id);
    this.getAssigneeUsers();
    //}
  }

  getAssigneeUsers() {

    if (this.selectedTeam) {

      if (this.httpSubscriptionAssignUsers !== null) {
        this.httpSubscriptionAssignUsers.unsubscribe();
      }

      const social_ids = this.selectedAccounts.map((item) => {
        return item.id;
      });

      let postValue = {
        'social_ids': social_ids,
        'team_id': this.selectedTeam.id
      };

      this.isAssigneeUsersLoading = true;
      this.httpSubscriptionAssignUsers = this.socialAccountService.getAssigneeUsers(postValue).subscribe((res) => {
        this.isAssigneeUsersLoading = false;
        console.log("getAssigneeUsers res : ", res);
        if (res.code === 200) {
          this.assigneeUsers = res.data;
        }
      }, error => {
        this.isAssigneeUsersLoading = false;
      });
    }
  }

  getAutoHashtagText() {
    this.selectedAutoHashtags = [];

    var content = this.postForm.value.content
    let postValue = {
      'text': content,
      'limit': this.hashtagLimit,
      'position': this.hashtagPosition
    };

    this.isAutoHashtagLoading = true;
    this.socialAccountService.getAutoHashtagText(postValue).subscribe((res) => {
      this.isAutoHashtagLoading = false;
      console.log("getAssigneeUsers res : ", res);
      if (res.code === 200) {
        this.autoHashtagVisible = false;
        this.hashtagsFromText = res.data;
      } else {
        this.sharedService.displayNotification(this.sharedService.messageTypeError, 'Auto Hashtags', res?.message);
      }
    }, error => {
      this.isAutoHashtagLoading = false;
    });
  }

  autoHashtagModal() {
    this.isVisibleHashtagModal = true;
  }

  closeModal() {
    this.isVisibleHashtagModal = false;
    this.autoHashtagVisible = false;
  }

  autoHashTagDrawer() {
    this.getAutoHashtagText();
    this.autoHashTag = true;
  }

  closeAutoHashtag() {
    this.autoHashTag = false;
    this.hashtagsFromText = [];
    this.selectedAutoHashtags = [];
  }

  updateAutoHashtag() {
    this.selectedAutoHashtags = [];
    this.selectedAutoHashtags = this.hashtagsFromText.filter((item) => {
      if (item.is_checked) {
        return item;
      }
    });
  }

  selectAllAutoHashtags() {
    this.selectedAutoHashtags = [];
    this.hashtagsFromText.map((item) => {
      item.is_checked = true;
    });
    this.updateAutoHashtag();
  }

  deselectAllAutoHashtags() {
    this.selectedAutoHashtags = [];
    this.hashtagsFromText.map((item) => {
      item.is_checked = false;
    });
  }

  addAutoHashtagToPost() {
    var hashtags = this.hashtagsFromText.filter(opt => opt.is_checked).map(opt => " #" + opt.hashtag).toString().replace(/,/g, " ");
    if (this.postForm.get('content').value !== '') {
      var appendContent = this.postForm.get('content').value + " " + hashtags;
      this.postForm.get('content').setValue(appendContent);
    } else {
      this.postForm.controls.content.setValue(hashtags);
    }

    this.closeAutoHashtag()
  }


  resetWriteForMe() {
    // console.log("this.selectedTone",this.selectedTone);
    this.articles = [];
    this.articleText = '';
    // this.selectedPlatformText = this.platformTexts[0];
    this.selectedTone = this.toneTypes[0];
  }

  writeForMe() {
    this.isWriteForMe = true;
    this.resetWriteForMe();
  }

  closeWfmDrawer() {
    this.isWriteForMe = false;
    this.resetWriteForMe();
  }

  getArticleSettings() {
    this.socialAccountService.getArticleSettings().subscribe((res) => {
      if (res.code === 200) {
        let data = res.data;

        // this.platformTexts = data.platform_titles;
        this.toneTypes = data.tones;
        this.remaining_chatgpt_article_limit = data.remaining_chatgpt_article_limit;
        this.total_chatgpt_article_limit = data.total_chatgpt_article_limit;
        //
        // this.selectedPlatformText = this.platformTexts[0];
        if(!this.selectedTone){
          this.selectedTone = this.toneTypes[0];
        }
      } else {
        this.sharedService.displayNotification(this.sharedService.messageTypeError, 'Write for me', res?.message);
      }
    }, error => {
    });
  }


  getArticles() {

    let postData = {
      'platform_text': this.selectedPlatformText,
      'tone': this.selectedTone,
      'text': this.articleText,
    };

    this.isArticleLoading = true;
    this.socialAccountService.getArticles(postData).subscribe((res) => {
      this.isArticleLoading = false;
      if (res.code === 200) {
        this.articles = res.data;
        console.log("articles : ", this.articles);

      } else {
        this.sharedService.displayNotification(this.sharedService.messageTypeError, 'Write for me', res?.message);
      }

      this.getArticleSettings();

    }, error => {
      this.isArticleLoading = false;
    });

  }

  copyText(textToCopy: string) {

    var articleText = textToCopy.trim();
    /*this.clipboard.copy(textToCopy.trim());
    this.sharedService.displayNotification(this.sharedService.messageTypeSuccess, 'Write for me', "Copied to clipboard!");*/

    if (this.postForm.get('content').value !== '') {
      var appendContent = this.postForm.get('content').value + " " + articleText;
      this.postForm.get('content').setValue(appendContent);
    } else if (articleText != '') {
      this.postForm.get('content').setValue(articleText);
    }

    this.closeWfmDrawer();
  }

  regenerateArticles() {
    console.log("this.selectedTone",this.selectedTone);
    this.articles = [];
  }


  getTikTokCreatorInfo(userSocialConnectionId) {

    this.isLoadingPost = true;
    let accountAlreadyExists = false;
    let creatorFormControl = (this.postForm.controls['tiktok_creator_info'] as FormArray).controls;
    creatorFormControl.forEach(element => {
      if (element.value.social_id == userSocialConnectionId) {
        accountAlreadyExists = true;
      }
    });
    if (!accountAlreadyExists) {
      this.tiktokService.getTikTokCreatorInfo(userSocialConnectionId).subscribe(
        (res) => {
          if (res.code === 200) {
            let creatorInfo = res.data;
            const formName = this.formBuilder.group({
              'social_id': userSocialConnectionId,
              'privacy_level': [null, [Validators.required]],
              'allow_comment': false,
              'allow_duet': false,
              'allow_stitch': false,
              'privacy_level_options': JSON.stringify(creatorInfo.privacy_level_options),
              'max_video_post_duration_sec' : creatorInfo.max_video_post_duration_sec
            });
            (this.postForm.controls['tiktok_creator_info'] as FormArray).push(formName);
            let index = this.selectedAccounts.findIndex((element) => element.social_id == userSocialConnectionId);
            this.selectedAccounts[index].privacy_level = creatorInfo.privacy_level_options_display;
          } else {
            let index = this.selectedAccounts.findIndex((element) => element.social_id == userSocialConnectionId);
            if(index != -1){
              this.selectedAccounts = this.selectedAccounts.filter((s)=>s.social_id !== userSocialConnectionId);
              this.postForm.get('accounts_id').setValue(this.selectedAccounts);
            }
            this.updateAccounts(this.selectedAccounts);
            if(res.code == 403){
              this.sharedService.displayNotification(this.sharedService.messageTypeError, 'Tik Tok', res.message);
            } else {
              this.sharedService.displayNotification(this.sharedService.messageTypeError, 'Tik Tok', 'Something went wrong');
            }
          }
          this.isLoadingPost = false;
        });
    }
  }

  getAccountPrivacyLevel(userSocialConnectionId) {
    return this.selectedAccounts[this.selectedAccounts.findIndex((element) => element.social_id == userSocialConnectionId)]['privacy_level'];
  }

  getAccountNameLevel(userSocialConnectionId) {
    //console.log("getAccountNameLevel userSocialConnectionId",userSocialConnectionId)
    //console.log("getAccountNameLevel selectedAccounts",this.selectedAccounts)
    //return this.selectedAccounts[this.selectedAccounts.findIndex((element) => element.social_id == userSocialConnectionId)]['name'];
    const foundAccount = this.selectedAccounts.find((element) => element.social_id == userSocialConnectionId);
    if (foundAccount && foundAccount['name']) {
      return foundAccount['name'];
    } else {
      return '';
    }
  }

  getFormatedLable(label) {
    return label.replace(/_/g, ' ');
  }

  getPercentage(): number {
    const used_chatgpt_article_limit = this.total_chatgpt_article_limit - this.remaining_chatgpt_article_limit;
    return (used_chatgpt_article_limit / this.total_chatgpt_article_limit) * 100;
  }

  formatProgress = (percent: number): string => {
    console.log("formatProgress" + this.remaining_chatgpt_article_limit/this.total_chatgpt_article_limit)
    return `${this.remaining_chatgpt_article_limit}/${this.total_chatgpt_article_limit}`;
  }

  gotoSubscription(){
    this.router.navigate(['/subscription']);
  }

  /*adddon*/
  getAddons() {
    this.selectedAddon = null;
    this.userService.getAddonDetail().subscribe(
      (res) => {
        if (res.code === 200) {
          this.selectedAddon = res.data.filter((item) => item.plan_id === "chatgpt")[0];
        }
      },
      (error) => {
        console.error(error);
      }
    );
  }


  cancelPayment(){
    this.isPaymentModalVisible = false;
  }

  submitHandelForAddon(data: any){
    console.log('Received data from child component:', data);
    if(data.status){
      this.isPaymentModalVisible = false;
      this.getArticleSettings();
    }
  }

  connectNewAccount(){
    this.isAccountConnectModal=true;
  }


  isPostingModal = false;
  postingModal(){
    this.isPostingModal = true;
  }

  tryAgainPost(){
    this.isPostingModal = false;
    this.scrollToTop();
    let tempPostFailed = this.postFailed;
    console.log("tryAgainPost postSuccess",this.postSuccess)
    console.log("tryAgainPost postFailed",this.postFailed)

    //remove posted accounts from select
    const already_posted_account_id = [];
    if(this.postSuccess.length>0) {
      this.postSuccess.forEach((item) => {
        already_posted_account_id.push(item.id);
      });
      console.log("tryAgainPost already_posted_account_id",already_posted_account_id)

      this.selectedAccounts = this.selectedAccounts.filter((item) =>
        !already_posted_account_id.includes(item.id)
      );

      //reset TikTok
      this.selectedAccounts.forEach((item) => {
        if(item.provider === this.sharedService.tiktokProvider){
          this.getTikTokCreatorInfo(item.social_id);
        }
      });

      this.postForm.patchValue({accounts_id: this.selectedAccounts});

      this.allAccountProvider = [];
      this.selectedAccounts.forEach((item) => {
        if (!this.allAccountProvider.includes(item.provider)) {
          this.allAccountProvider.push(item.provider);
        }
      });

      //keep it to display error on try again on warning
      this.postFailed = tempPostFailed;
    }

  }

  async closeWindow() {
    this.isPostingModal = false;
    this.scrollToTop();
    this.resetFormData();

    this.selectedAccounts = [];
    this.initForm();
    await this.setDefaultTeam();
  }

  viewPost(){
    /*this.router.navigate(['/post/manage-post'], {
      queryParams: { post: 'delivered-post' },
    });*/

    if(this.selectedAssigneeUsers.length === 0) {
      if (this.loadingPostType === 'post') {
        this.router.navigate(['/post/manage-post'], {
          queryParams: {post: 'delivered-post'},
        });
      } else if (this.loadingPostType === 'queue') {
        this.router.navigate(['/post/manage-post'], {
          queryParams: {post: 'queue-post'},
        });
      } else if (this.loadingPostType === 'draft') {
        this.router.navigate(['/post/manage-post'], {
          queryParams: {post: 'draft-post'},
        });
      } else {
        this.router.navigate(['/post/manage-post'], {
          queryParams: {post: 'schedule-post'},
        });
      }
    }else{
      this.router.navigate(['/post/manage-post'], {
        queryParams: { post: 'draft-post' },
      });
    }
  }

  isToggle(i){
    if(this.isSelectedAccountToggle == i){
      this.isSelectedAccountToggle = null;
    }else{
      this.isSelectedAccountToggle = i;
    }
  }

  resolveError(){
    this.isPostingModal = false;
    this.scrollToTop();
  }

  scrollToTop(){
    this.isSelectedAccountToggle = null;
    //scroll top
    setTimeout(()=>{
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    },500);
  }

  async resetFormData() {
    console.log("selectedAccount while resetFormData Before",this.selectedAccounts);
    this.postForm.reset();
    console.log("selectedAccount while resetFormData",this.selectedAccounts);

    //for reset media
    this.instaReelThumbnails = [];
    this.images = [];
    // this.uploadFiles = [];
    this.galleryMediaData = [];
    // this.mediaData = [];
    this.postMedias = [];
    this.accountProvider = this.sharedService.twitterProvider;
    this.maxLength = 10;
    this.type = 'image';
    this.selectedLocation = null;
    this.error = '';

    await this.setDefaultTeam();

    this.selectedFacebookMentions = [];
    this.selectedInstagramMentions = [];
    this.selectedTwitterMentions = [];
    this.selectedAccounts = [];
  }


  async setDefaultTeam() {
    console.log("setDefaultTeam team:",this.teams)
    if (this.teams?.length > 0) {

      var activeTeam = localStorage.getItem('team_id');
      var activeTeamId = parseInt(activeTeam);
      //this.selectedTeam = this.teams[0];
      this.teams.map((item) => {
        if (item.id === activeTeamId) {
          this.selectedTeam = item;
        }
      });
      console.log("setDefaultTeam selectedTeam:",this.selectedTeam)

      //this.ddTeamClass = "post_accounts "+ this.sharedService.dropdownHeight[this.teams.length<5 ? this.teams.length-1 : 'post_accounts2']
      this.ddTeamClass = ['post_accounts', this.sharedService.dropdownHeight[this.teams.length < 6 ? this.teams.length - 1 : 5]]
      this.postForm.patchValue({ team: this.selectedTeam });
      await this.getActiveAccounts(this.selectedTeam?.id);

    }
  }

  countKeys(val){
    const propertiesArray = Object.getOwnPropertyNames(val);
    //console.log("countKeys propertiesArray",propertiesArray)
    const count = propertiesArray.length;
    return count;
  }

  //Drag and drop file in form tag
  onDocumentDragOver(event: DragEvent) {
    // Prevent the default behavior to enable dropping
    event.preventDefault();

    // Check if files are being dragged
    const hasFiles = event.dataTransfer?.types.includes('Files');

    if (hasFiles) {
      document.body.classList.add('dragging');
      window.scrollTo(0, 0);
    } else {
      document.body.classList.remove('dragging');
    }

  }

  onDrop(event: DragEvent) {
    // Prevent the default behavior to enable dropping
    event.preventDefault();

    // Handle the drop event
    const files = event.dataTransfer?.files;
    if (files) {
      // console.log('File dropped:', files[0].name);
      // Additional actions you want to perform when a file is dropped
    }

    // Remove the 'dragging' class from the body
    document.body.classList.remove('dragging');
  }

  onDragLeave(event:DragEvent){
    event.preventDefault();

    if (!this.isMouseWithinDiv(event)) {
      // Remove the class or perform other actions
      document.body.classList.remove('dragging');
    }
  }

  private isMouseWithinDiv(event: DragEvent): boolean {
    const rect = (event.target as HTMLElement).getBoundingClientRect();
    const x = event.clientX;
    const y = event.clientY;

    return x >= rect.left && x <= rect.right && y >= rect.top && y <= rect.bottom;
  }


  showLocationModal(){
    this.isShowLocation = true;
  }

  hideLocationModal(){
    this.selectedLocation = null;
    this.isShowLocation = false;
  }

  selectLocation(){
    this.isShowLocation = false;
  }

  mentionSelect(item) {
    console.log('mentionSelect', item);
    //return item.username;
    return '@['+item.id+']';
  }


  facebookLocationSearch(event){
    console.log("facebookLocationSearch event :", event)
    const facebookAccounts = this.selectedAccounts.filter((item) =>
        [this.sharedService.facebookProvider, this.sharedService.instagramProvider].includes(item.provider)
    );
    if(facebookAccounts.length>0){
      if (this.httpFacebookLocationSubscription !== null) {
        this.httpFacebookLocationSubscription.unsubscribe();
      }
      this.isLocationLoading = true;
      this.httpFacebookLocationSubscription = this.socialAccountService.getFacebookLocation(event,facebookAccounts[0].id).subscribe((res) => {
            //console.log("facebookLocationSearch res : ", res)
            this.isLocationLoading = false;
            if (res.code === 200) {
              this.postLocations = res.data;
            }
          },(error) => {
            console.error("facebookLocationSearch error : ", error);
            this.isLocationLoading = false;
          }
      );
    }
  }

  platFormSpecificDrawer(){
    this.isPlatFormSpecific = true;
  }

  closePlatFormSpecificDrawer(){
    this.isPlatFormSpecific = false;
  }

  facebookPageSearch(event: string) {
    this.facebookPageSearchSubject.next(event);
  }

  performFacebookPageSearch(event: string) {
    console.log("facebookPageSearch event :", event);
    if (this.httpFacebookPageSearchSubscription !== null) {
      this.httpFacebookPageSearchSubscription.unsubscribe();
    }

    this.facebookPages = [];
    const facebookAccounts = this.selectedAccounts.filter((item) =>
        [this.sharedService.facebookProvider, this.sharedService.instagramProvider].includes(item.provider)
    );
    if (facebookAccounts.length > 0) {
      this.isFacebookPageSearchLoading = true;
      this.httpFacebookPageSearchSubscription = this.socialAccountService.getFacebookPageSearch(event, facebookAccounts[0].id).subscribe((res) => {
            this.isFacebookPageSearchLoading = false;
            if (res.code === 200) {
              this.facebookPages = res.data;
            }
          }, (error) => {
            console.error("facebookPageSearch error : ", error);
            this.isFacebookPageSearchLoading = false;
          }
      );
    }
  }

  addFacebookMentionList(){
    //add to array
    this.selectedFacebookMentions = [...this.selectedFacebookMentions, ...this.facebookSearchPagesList];

    let facebookPagesName = this.facebookSearchPagesList.map((item)=> item.name);
    console.log("addFacebookMentionList facebookPagesName",facebookPagesName)
    let facebookPagesNameString = ' '+facebookPagesName.join(' ');
    console.log("addFacebookMentionList facebookPagesNameString",facebookPagesNameString)


    const selectionStart = this.inputContent.nativeElement.selectionStart;
    var currentValue = this.postForm.value.content;
    if (this.postForm.value.content && this.postForm.value.content.length > 0) {
      const newValue = currentValue.substring(0, selectionStart) + facebookPagesNameString + currentValue.substring(selectionStart);
      this.postForm.controls.content.setValue(newValue);
    } else {
      currentValue = facebookPagesNameString;
      this.postForm.controls.content.setValue(currentValue);
    }
    this.inputContent.nativeElement.selectionStart = selectionStart + facebookPagesNameString.length;
    this.inputContent.nativeElement.selectionEnd = selectionStart + facebookPagesNameString.length;

    //reset
    this.facebookSearchPagesList = [];
  }

  addInstagramMentionList(){
    //add to array
    this.selectedInstagramMentions = [...this.selectedInstagramMentions, ...this.instagramSearchPagesList];

    let instagramNameString = ' '+this.instagramSearchPagesList.join(' ');
    console.log("addInstagramMentionList instagramNameString",instagramNameString)


    const selectionStart = this.inputContent.nativeElement.selectionStart;
    var currentValue = this.postForm.value.content;
    if (this.postForm.value.content && this.postForm.value.content.length > 0) {
      const newValue = currentValue.substring(0, selectionStart) + instagramNameString + currentValue.substring(selectionStart);
      this.postForm.controls.content.setValue(newValue);
    } else {
      currentValue = instagramNameString;
      this.postForm.controls.content.setValue(currentValue);
    }
    this.inputContent.nativeElement.selectionStart = selectionStart + instagramNameString.length;
    this.inputContent.nativeElement.selectionEnd = selectionStart + instagramNameString.length;

    //reset
    this.instagramSearchPagesList = [];
  }

  addTwitterMentionList(){
    //add to array
    this.selectedTwitterMentions = [...this.selectedTwitterMentions, ...this.twitterSearchPagesList];

    let usernameString = ' '+this.twitterSearchPagesList.join(' ');
    console.log("addTwitterMentionList usernameString",usernameString)

    const selectionStart = this.inputContent.nativeElement.selectionStart;
    var currentValue = this.postForm.value.content;
    if (this.postForm.value.content && this.postForm.value.content.length > 0) {
      const newValue = currentValue.substring(0, selectionStart) + usernameString + currentValue.substring(selectionStart);
      this.postForm.controls.content.setValue(newValue);
    } else {
      currentValue = usernameString;
      this.postForm.controls.content.setValue(currentValue);
    }
    this.inputContent.nativeElement.selectionStart = selectionStart + usernameString.length;
    this.inputContent.nativeElement.selectionEnd = selectionStart + usernameString.length;

    //reset
    this.twitterSearchPagesList = [];
  }

  addThreadsMentionList(){
    //add to array
    this.selectedThreadsMentions = [...this.selectedThreadsMentions, ...this.threadsSearchPagesList];

    let usernameString = ' '+this.threadsSearchPagesList.join(' ');
    console.log("addTwitterMentionList usernameString",usernameString)

    const selectionStart = this.inputContent.nativeElement.selectionStart;
    var currentValue = this.postForm.value.content;
    if (this.postForm.value.content && this.postForm.value.content.length > 0) {
      const newValue = currentValue.substring(0, selectionStart) + usernameString + currentValue.substring(selectionStart);
      this.postForm.controls.content.setValue(newValue);
    } else {
      currentValue = usernameString;
      this.postForm.controls.content.setValue(currentValue);
    }
    this.inputContent.nativeElement.selectionStart = selectionStart + usernameString.length;
    this.inputContent.nativeElement.selectionEnd = selectionStart + usernameString.length;

    //reset
    this.threadsSearchPagesList = [];
  }

  addTiktokMentionList(){
    //add to array
    this.selectedTiktokMentions = [...this.selectedTiktokMentions, ...this.tiktokSearchPagesList];

    let usernameString = ' '+this.tiktokSearchPagesList.join(' ');
    console.log("addTiktokMentionList usernameString",usernameString)

    const selectionStart = this.inputContent.nativeElement.selectionStart;
    var currentValue = this.postForm.value.content;
    if (this.postForm.value.content && this.postForm.value.content.length > 0) {
      const newValue = currentValue.substring(0, selectionStart) + usernameString + currentValue.substring(selectionStart);
      this.postForm.controls.content.setValue(newValue);
    } else {
      currentValue = usernameString;
      this.postForm.controls.content.setValue(currentValue);
    }
    this.inputContent.nativeElement.selectionStart = selectionStart + usernameString.length;
    this.inputContent.nativeElement.selectionEnd = selectionStart + usernameString.length;

    //reset
    this.tiktokSearchPagesList = [];
  }

  addTags(){
    this.closePlatFormSpecificDrawer();

    this.addFacebookMentionList();

    this.addInstagramMentionList();
    this.addTwitterMentionList();

    this.addThreadsMentionList();
    this.addTiktokMentionList();
  }

  getValidUsernames(searchValue){
    return searchValue.map(tag => {
      const trimmedTag = tag.trim().replace(/\s+/g, '');
      const cleanTag = trimmedTag.replace(/@+/g, '@'); // remove multiple @ signs
      const username = cleanTag.replace(/^@+|@+$/g, ''); // remove @ from start and end
      const validUsername = username.replace(/[^a-zA-Z0-9_]/g, ''); // remove non-alphanumeric characters
      return `@${validUsername}`;
    });
  }

  validateInstagramSearchInput(searchValue: string[]): void {
    console.log("validateInstagramSearchInput searchValue", searchValue)
    this.instagramSearchPagesList = [];
    const validTags = searchValue.map(tag => {
      const trimmedTag = tag.trim().replace(/\s+/g, '');
      const cleanTag = trimmedTag.replace(/@+/g, '@'); // remove multiple @ signs
      const username = cleanTag.replace(/^@+|@+$/g, ''); // remove @ from start and end
      const validUsername = username.replace(/[^a-zA-Z0-9_]/g, ''); // remove non-alphanumeric characters
      return `@${validUsername}`;
    });
    this.instagramSearchPagesList = [...this.instagramSearchPagesList, ...validTags];

  }

  validateTwitterSearchInput(searchValue: string[]): void {
    console.log("validateTwitterSearchInput searchValue", searchValue)
    this.twitterSearchPagesList = [];
    const validTags = searchValue.map(tag => {
      const trimmedTag = tag.trim().replace(/\s+/g, '');
      const cleanTag = trimmedTag.replace(/@+/g, '@'); // remove multiple @ signs
      const username = cleanTag.replace(/^@+|@+$/g, ''); // remove @ from start and end
      const validUsername = username.replace(/[^a-zA-Z0-9_]/g, ''); // remove non-alphanumeric characters
      return `@${validUsername}`;
    });
    this.twitterSearchPagesList = [...this.twitterSearchPagesList, ...validTags];
  }

  validateThreadsSearchInput(searchValue: string[]): void {
    console.log("validateThreadsSearchInput searchValue", searchValue)
    this.threadsSearchPagesList = [];
    const validTags = this.getValidUsernames(searchValue);
    this.threadsSearchPagesList = [...this.threadsSearchPagesList, ...validTags];
  }

  validateTiktokSearchInput(searchValue: string[]): void {
    console.log("validateTiktokSearchInput searchValue", searchValue)
    this.tiktokSearchPagesList = [];
    const validTags = this.getValidUsernames(searchValue);
    this.tiktokSearchPagesList = [...this.tiktokSearchPagesList, ...validTags];
  }


  updateImagesIndex(event): void {
    console.log("updateImagesIndex : ",event);
    this.postMedias = event.images;
    /*setTimeout(()=>{
        this.postMedias = this.images.map(fileName => {
          return this.postMedias.find(item => item.media_file === fileName);
        }).filter(item => item !== undefined);
        //console.log("updateImages mediaData 3: ",this.postMedias);

        this.galleryMediaData = this.images.map(fileName => {
          return this.galleryMediaData.find(item => item.media_file === fileName);
        }).filter(item => item !== undefined);
        //this.multiImages = this.mediaData.concat(this.galleryMediaData);
      },100);*/
  }

  closeImageGenerateModal(){
    this.isImageGenerateModal = false;

    this.generatedImages = [];
    this.selectedGeneratedImage = [];
  }


  generateImages(){
    if (this.httpSubscriptionImageGenerate !== null) {
        this.httpSubscriptionImageGenerate.unsubscribe();
    }
    this.isImageGenerating = true;
    this.generatedImages = [];

    var postValue: any = {
      'text' : this.textToImage,
      'style' : this.styleSelection,
      'size' : this.styleAspectRatio,
    };

    this.httpSubscriptionImageGenerate = this.userGalleryService.generateImages(postValue).subscribe((res) => {
      console.info("generateImages res :", res);
      this.isImageGenerating = false;
      if (res.code === 200) {
          this.generatedImages = res.data;
          //console.log('this.generatedImages',this.generatedImages);
      }else{
        this.sharedService.displayNotification(this.sharedService.messageTypeError,
            this.sharedService.defaultMessageError,
            res?.message || 'Something went wrong, try again later.');
      }
      },(error) => {
        console.error("generateImages error : ", error);
        this.isImageGenerating = false;
        this.sharedService.displayNotification(this.sharedService.messageTypeError, this.sharedService.defaultMessageError, 'Something went wrong, try again later.');
      }
    );

  }

  viewGeneratedImage(img){
    console.log("viewGeneratedImage");
    this.previewGeneratedImage = img;
    this.isPreviewGeneratedImageModal = true;
  }

  generateVariantImage(){
    console.log("generateVariantImage")
    if (this.httpSubscriptionImageGenerate !== null) {
      this.httpSubscriptionImageGenerate.unsubscribe();
    }
    this.isImageRegenerating = true;
    //this.generatedImages = [];

    var postValue: any = {
      'text' : this.refineTextToImage,
      //'style' : this.styleSelection,
      'size' : this.styleAspectRatio,
      'image' : this.selectedGeneratedImage[0],
    };

    let selectedImageIndex = this.generatedImages.indexOf(this.selectedGeneratedImage[0]);
    this.httpSubscriptionImageGenerate = this.userGalleryService.generateImageVariant(postValue).subscribe((res) => {
        console.info("generateVariantImage res :", res);
        this.isImageRegenerating = false;


        if (res.code === 200) {
          this.generatedImages = this.generatedImages.map((item,itemIndex)=>{
            if(itemIndex!==selectedImageIndex){
              return res.data.shift();
            }
            return item;
          });
          //this.generatedImages[selectedImageIndex] = res.data[0];
          this.selectedGeneratedImage.length == 0;
        }else{
          this.sharedService.displayNotification(this.sharedService.messageTypeError,
              this.sharedService.defaultMessageError, res?.message || 'Something went wrong, try again later.');
        }
      },(error) => {
        console.error("generateVariantImage error : ", error);
        this.isImageRegenerating = false;
        this.sharedService.displayNotification(this.sharedService.messageTypeError, this.sharedService.defaultMessageError, 'Something went wrong, try again later.');
    });
  }

  clearAiInput(){
    this.textToImage = '';
  }

  generatedImagesEvent(event){
    // console.log(event,"eventeventevent");
    this.selectedGeneratedImage = event;
    console.log(this.selectedGeneratedImage.length,"this.selectedGeneratedImage.length");
  }

  //Clear all accounts in Post to
  clearAllAccounts(){
    this.selectedAccounts = [];
    this.allAccountProvider = [];
    this.pinterestAccounts = [];
    this.tiktokAccounts = [];
    this.postForm.patchValue({ accounts_id: [] });
  }

  //Select all accounts in Post to
  selectAllAccounts() {
    let allAccounts = [];
    for (let provider of Object.keys(this.groupedAccounts)) {
      allAccounts = allAccounts.concat(this.groupedAccounts[provider]);
    }
    this.postForm.get('accounts_id').setValue(allAccounts);
    console.log("selectAllAccounts while close window",allAccounts);
    this.selectedAccounts = allAccounts;
  }

  // Select or Deselect all platform from providers label
  onProviderCheckboxChange(providerKey: any, event: any): void {
    const providerAccounts = this.accounts.filter(account => account.provider === providerKey);
    console.log("onProviderCheckboxChange",providerAccounts);
    if(event.target.checked){
      this.selectedAccounts = [...this.selectedAccounts, ...providerAccounts];
    }else{
      this.selectedAccounts = this.selectedAccounts.filter(
        account => account.provider !== providerKey
      );
    }
  }

  // Select provider checkbox if all platform is selected
  areAllProviderAccountsSelected(providerKey: string): boolean {
    const providerAccounts = this.accounts.filter(account => account.provider === providerKey);
    return providerAccounts.every(account =>
      this.selectedAccounts.some(selected => selected.id === account.id)
    );
  }

  closeDropdown() {
    this.isOpenSelect = false;
    document.body.classList.remove('openNzSelect');
  }

  closeaiImagePrompt(){
    this.isImageGenerateModal = false;
  }

  aiImageGeneration(){
    console.log(this.styleSelection,"this.styleSelection");
    console.log(this.styleAspectRatio,"this.styleAspectRatio");
  }

  selectAiImages(even){
    console.log(even,"event");
  }

  downloadGeneratedImages(){
    console.log("downloadGeneratedImages")

    this.selectedGeneratedImage.forEach((base64Image, index) => {
      // Convert base64 string to a Blob
      const blob = this.base64ToBlob('data:image/jpg;base64,'+base64Image, 'image/jpg');

      // Create a URL for the Blob
      const url = window.URL.createObjectURL(blob);

      // Create an anchor element and trigger a download
      const a = document.createElement('a');
      a.href = url;
      a.download = `ofa_image-${index + 1}.jpg`; // specify the filename
      document.body.appendChild(a);
      a.click();

      // Clean up - remove the anchor element and revoke the URL
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    });
  }

  base64ToBlob(base64: string, contentType: string = '', sliceSize: number = 512) {
    const byteCharacters = atob(base64.split(',')[1]);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  }

  getTypeFromAllMedias(Medias){
    const hasImage = Medias.some(item => item.file_type === 'image' && item.file_mime_type !== 'image/gif');
    const hasVideo = Medias.some(item => item.file_type === 'video');
    const hasGif = Medias.some(item => item.file_mime_type === 'image/gif');
    // Set 'multi' if multiple media types are present (image + video or gif + another type)

    if ((hasImage && hasVideo) || (hasGif && (hasImage || hasVideo))) {
        this.type = 'multi';
    } else if (hasGif) {
        this.type = 'gif';
    } else if (hasImage) {
        this.type = 'image';
    } else if (hasVideo) {
        this.type = 'video';
    } else {
        this.type = 'multi';
    }
    console.log("getAllMediaType Final result : ",this.type );
    return this.type;
  }

}
