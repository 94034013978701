<nz-card nzTitle="Preview Post" class="card sliderBtnWrapper" *ngIf="from!==''">
  <div class="preview_post_wrp_data">
    <div class="preview_post_header">
      <div class="preview_post_hdr_left">
        <div class="img_wrp">
          <app-image-preview [imageUrl]="profilePic" [class]="'account-list-image'" [isVideo]="false">
          </app-image-preview>
          <app-provider [className]="'social-icon'" [provider]="provider"></app-provider>
        </div>
        <div class="title_wrp">
          <strong>{{name}}</strong>
        </div>
        <div class="time_zone">
          <span>{{time}}</span>
        </div>
      </div>
    </div>
    <div class="preview_post_body emoji_text">
      <p *ngIf="content" class="contentBody">{{content}} </p>
      <br>
      <p *ngIf="title"><b>Title :</b> {{title}}</p>
      <br>

      <!--*ngIf="type === 'image' || type === 'gif'"-->
      <div class="post_img_structure">
        <div class="post_img_structure_in" [ngClass]="post_img_class">
          <ng-container *ngFor="let media of postMedias; let i = index">
            <div *ngIf="i < 4" (click)="preview(i + 1)">
              <app-image-preview [imageUrl]="media.media_file" class="preview-post-img" [isVideo]="media.file_type==='video'"></app-image-preview>
              <span>+{{postMedias.length - 3}}</span>
            </div>
          </ng-container>
        </div>
      </div>

      <!--<ng-container *ngIf="postMedias.length > 0 && type === 'video'">
        <div class="upload_video">
          <div class="upload_video_in">
            <app-image-preview [imageUrl]="postMedias[0].media_file" class="preview-post-video" [isVideo]="true">
            </app-image-preview>
          </div>
        </div>
      </ng-container>-->

    </div>

    <ng-container *ngIf="comment !== null &&  comment !== ''&& comment !== undefined">
      <div class="comment-section">
        <div nz-row class="comment-inner">
          <div nz-col nzSpan="3">
            <div class="img_wrp">
              <app-image-preview [imageUrl]="profilePic" [class]="'account-list-image'" [isVideo]="false">
              </app-image-preview>
            </div>
          </div>
          <div nz-col nzSpan="21" class="comment-wrap">
            <div class="title">
              <strong>{{name}}</strong>
            </div>
            <div class="comment">
              <p>{{comment}}</p>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

  </div>
</nz-card>
<!-- card end -->

<nz-carousel class="nzcarouselCNcustom" [nzEffect]="effect"
             [nzDots]="displaySliderItem && userData.length > 1 ? true  : false" [nzAutoPlay]="false"
             [nzEnableSwipe]="userData.length > 1 ? true  : false" *ngIf="from===''"
             (nzBeforeChange)="previewSliderChanges($event)">

  <div nz-carousel-content *ngFor="let item of userData; let i = index">

    <div class="pre_content_social" *ngIf="displayPlatformDetails">
      <div *ngIf="item.provider === sharedService.twitterProvider">
        <img src="assets/images/Twitter.png" alt="Twitter Logo" class="social-icon">
        <span>Twitter</span>
      </div>
      <div *ngIf="item.provider === sharedService.linkedinProvider">
        <img src="assets/images/LinkedIn.png" alt="LinkedIn Logo" class="social-icon">
        <span>LinkedIn</span>
      </div>
      <div *ngIf="item.provider === sharedService.facebookProvider">
        <img src="assets/images/Facebook.png" alt="Facebook Logo" class="social-icon">
        <span>Facebook</span>
      </div>
      <div *ngIf="item.provider === sharedService.instagramProvider">
        <img src="assets/images/Instagram.png" alt="Instagram Logo" class="social-icon">
        <span>Instagram</span>
      </div>
      <div *ngIf="item.provider === sharedService.youtubeProvider">
        <img src="assets/images/YouTube.png" alt="YouTube Logo" class="social-icon">
        <span>YouTube</span>
      </div>
      <div *ngIf="item.provider === sharedService.tiktokProvider">
        <img src="assets/images/tiktok.svg" alt="tiktok" class="social-icon">
        <span>TikTok</span>
      </div>
      <div *ngIf="item.provider === sharedService.pinterestProvider">
        <img src="assets/images/Pinterest.png" alt="Pinterest Logo" class="social-icon">
        <span>Pinterest</span>
      </div>
      <div *ngIf="item.provider === sharedService.threadsProvider">
        <img src="assets/images/threads.png" alt="Threads Logo" class="social-icon">
        <span>Threads</span>
      </div>

      <div class="btn_main" *ngIf="displayAction">
        <button class="ant-btn btn_edit" title="Edit Post" (click)="editPost(id)" *ngIf="post.isEdit">
          <svg width="23" height="22" viewBox="0 0 23 22" fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <path
              d="M15.6163 4.13023L18.3698 6.88238L15.6163 4.13023ZM17.387 1.70569L9.94173 9.15092C9.55703 9.53507 9.29467 10.0245 9.18771 10.5575L8.5 14L11.9425 13.311C12.4755 13.2044 12.9643 12.9431 13.3491 12.5583L20.7943 5.11305C21.018 4.88932 21.1955 4.62371 21.3166 4.33139C21.4377 4.03907 21.5 3.72577 21.5 3.40937C21.5 3.09296 21.4377 2.77966 21.3166 2.48734C21.1955 2.19502 21.018 1.92942 20.7943 1.70569C20.5706 1.48196 20.305 1.30448 20.0127 1.1834C19.7203 1.06232 19.407 1 19.0906 1C18.7742 1 18.4609 1.06232 18.1686 1.1834C17.8763 1.30448 17.6107 1.48196 17.387 1.70569V1.70569Z"
              stroke="#F5F6F2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path
              d="M18.2652 15.4119V18.7649C18.2652 19.3578 18.0296 19.9263 17.6104 20.3455C17.1912 20.7648 16.6227 21.0003 16.0298 21.0003H3.73535C3.1425 21.0003 2.57393 20.7648 2.15472 20.3455C1.73551 19.9263 1.5 19.3578 1.5 18.7649V6.47046C1.5 5.87761 1.73551 5.30904 2.15472 4.88983C2.57393 4.47062 3.1425 4.23511 3.73535 4.23511H7.08838"
              stroke="#F5F6F2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </button>
        <button class="ant-btn btn_delete" title="Delete Post" (click)="deletePost(id)" *ngIf="post.isDelete">
          <svg width="20" height="21" viewBox="0 0 20 21" fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <path
              d="M6.0625 4.35294L6.6745 2.52894C6.82372 2.08384 7.11013 1.69666 7.49315 1.42226C7.87618 1.14785 8.3364 1.00012 8.80862 1H11.1902C11.6627 0.999887 12.1231 1.14751 12.5064 1.42193C12.8896 1.69635 13.1762 2.08366 13.3255 2.52894L13.9375 4.35294M3.25 4.35294L4.30075 18.9617C4.32093 19.2434 4.4477 19.507 4.65557 19.6997C4.86344 19.8923 5.13699 19.9996 5.42125 20H14.5765C14.861 19.9999 15.1348 19.8927 15.3429 19.7C15.551 19.5074 15.6779 19.2436 15.6981 18.9617L16.75 4.35294H3.25ZM10 9.94118V14.4118V9.94118ZM19 4.35294H1H19Z"
              stroke="#fe2c2c" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </button>
      </div>

    </div>

    <div class="pre_sec"
      *ngIf="item && (item.provider !== sharedService.instagramProvider || (instagramType === 'feed_post' && item.provider === sharedService.instagramProvider))"
      [class]="platformClass[item.provider]">

      <ng-container *ngIf="item.provider === sharedService.youtubeProvider">
        <div class="youtube_video_sec">

          <!--*ngIf="postMedias.length > 0 && !isYoutubePost  && (type === 'video' || type==='multi')"-->
          <ng-container *ngIf="postMedias.length > 0 && !isYoutubePost">
            <div class="upload_video">
              <div class="upload_video_in yt_media_main">
                <a href="javascript:;" class="play_icon" (click)="playYTVideo()" *ngIf="YTThumbnail!==''">
                  <img src="../../../assets/images/YouTube.png" alt="YouTube">
                </a>
                <ng-container *ngIf="YTThumbnail!==''">
                  <app-image-preview [imageUrl]="YTThumbnail" class="preview-post-video" [isVideo]="false"></app-image-preview>
                </ng-container>
                <ng-container *ngIf="YTThumbnail===''">
                  <app-image-preview [imageUrl]="postMedias[0].media_file" class="preview-post-video" [isVideo]="true" [forceAutoPlay]="autoPlay"></app-image-preview>
                </ng-container>
              </div>
            </div>
          </ng-container>

          <!--<ng-container *ngIf="postMedias.length > 0 && type === 'video' && isYoutubePost && postId !== ''">-->
          <ng-container *ngIf="postMedias.length > 0 && isYoutubePost && postId !== ''">
            <ng-container *ngIf="!isIframeLoaded && YTThumbnail===''">
              <div class="single_row_skeleton">
                <nz-skeleton [nzParagraph]="{ rows: 0 }" [nzActive]="true"></nz-skeleton>
              </div>
            </ng-container>
            <div class="yt_media_main">
              <a href="javascript:;" class="play_icon" (click)="playYTVideo()" *ngIf="YTThumbnail!==''">
                <img src="../../../assets/images/YouTube.png" alt="YouTube">
              </a>
              <ng-container *ngIf="YTThumbnail!==''">
                <app-image-preview [imageUrl]="YTThumbnail" class="preview-post-video" [isVideo]="false"></app-image-preview>
              </ng-container>
              <ng-container *ngIf="YTThumbnail===''">
                <iframe width="100%" height="345" [src]="youtubeVideoUrl" (load)="isIframeLoaded=true" [class.hide_div]="!isIframeLoaded"></iframe>
              </ng-container>
            </div>
          </ng-container>
        </div>

        <div class="pre_user_sec">
          <div class="pre_user_left">
            <app-image-preview [imageUrl]="item.image" [class]="'account-list-image'" [isVideo]="false"></app-image-preview>
          </div>
          <div class="pre_user_right">
            <div class="youtube_video_title">
              <p *ngIf="title"><strong>{{title}}</strong></p>
              <!--<p *ngIf="content"> {{content}} </p>-->
              <ng-container *ngIf="isContentLoading">
                <nz-skeleton [nzActive]="true" [nzParagraph]="{ rows: 1 }"></nz-skeleton>
              </ng-container>
              <ng-container *ngIf="!isContentLoading">
                <p *ngIf="otherContent" [innerHTML]="otherContent"></p>
              </ng-container>
            </div>
            <div class="title">
              <strong>{{item.name }}</strong>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="item.provider === sharedService.tiktokProvider">
        <div class="tiktok_preview">
          <div class="img_sec" [class.no_tiktok_media]="postMedias.length === 0">
            <!--*ngIf="postMedias.length > 0 && type === 'video'"-->

            <ng-container *ngIf="postMedias.length > 0">

              <!--<nz-carousel [nzEffect]="effect">
                <div nz-carousel-content *ngFor="let media of postMedias">
                  <ng-container *ngIf="postMedias[0].file_type=== 'video'">
                    <app-image-preview *ngIf="media.file_type=== 'video'" [imageUrl]="media.media_file" class="preview-post-video" [isVideo]="true"></app-image-preview>
                  </ng-container>
                  <ng-container *ngIf="postMedias[0].file_type=== 'image'">
                    <app-image-preview *ngIf="media.file_type=== 'image'" [imageUrl]="media.media_file" class="preview-post-video" [isVideo]="false"></app-image-preview>
                  </ng-container>
                </div>
              </nz-carousel>-->
              <nz-carousel [nzEffect]="effect" *ngIf="postMedias[0].file_type=== 'video'">
                <ng-container *ngFor="let media of postMedias">
                  <div nz-carousel-content *ngIf="media.file_type=== 'video'">
                    <app-image-preview *ngIf="media.file_type=== 'video'" [imageUrl]="media.media_file" class="manage-post-video" [isVideo]="true"></app-image-preview>
                  </div>
                </ng-container>
              </nz-carousel>

              <nz-carousel [nzEffect]="effect" *ngIf="postMedias[0].file_type=== 'image'">
                <ng-container *ngFor="let media of postMedias">
                  <div nz-carousel-content *ngIf="media.file_type=== 'image'">
                    <app-image-preview *ngIf="media.file_type=== 'image'" [imageUrl]="media.media_file" class="preview-post-video" [isVideo]="false"></app-image-preview>
                  </div>
                </ng-container>
              </nz-carousel>

            </ng-container>
          </div>

          <div class="content_sec">
            <div class="content_left">
              <span>@{{item.name}}</span>
              <!--<span>{{content}}</span>-->
              <ng-container *ngIf="isContentLoading">
                <nz-skeleton [nzActive]="true" [nzParagraph]="{ rows: 1 }"></nz-skeleton>
              </ng-container>
              <ng-container *ngIf="!isContentLoading">
                <span [innerHTML]="tiktokContent"></span>
              </ng-container>
            </div>
            <div class="content_right">
              <div class="content_right_in">
                <div class="tiktok_user_top">
                  <span *ngIf="item.image !== ''; else userProfile">
                    <div class="pre_user_left">
                      <app-image-preview [imageUrl]="item.image" [class]="'account-list-image'" [isVideo]="false"></app-image-preview>
                    </div>
                  </span>
                  <ng-template #userProfile>
                    <span>{{item.namecharAt(0)}}</span>
                  </ng-template>
                </div>
                <div class="tiktok_icons">
                  <svg width="23" height="20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20.01 1.658c-2.294-1.955-5.705-1.603-7.81.569l-.825.85-.824-.85C8.45.055 5.035-.297 2.74 1.657.113 3.902-.025 7.928 2.327 10.36l8.098 8.362c.523.54 1.373.54 1.896 0l8.099-8.362c2.356-2.432 2.218-6.458-.41-8.701z" fill="#fff"/></svg>
                  <svg width="22" height="19" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.375.625c-5.8 0-10.5 3.741-10.5 8.358 0 1.992.878 3.817 2.338 5.251C2.7 16.259.986 18.063.965 18.084a.321.321 0 00.238.542c2.72 0 4.758-1.278 5.767-2.066 1.341.495 2.83.78 4.405.78 5.8 0 10.5-3.74 10.5-8.357 0-4.617-4.7-8.358-10.5-8.358zm-5.25 9.643a1.298 1.298 0 01-1.313-1.285c0-.712.587-1.286 1.313-1.286s1.313.574 1.313 1.286c0 .71-.587 1.285-1.313 1.285zm5.25 0a1.298 1.298 0 01-1.313-1.285c0-.712.587-1.286 1.313-1.286s1.313.574 1.313 1.286c0 .71-.587 1.285-1.313 1.285zm5.25 0a1.298 1.298 0 01-1.313-1.285c0-.712.587-1.286 1.313-1.286s1.313.574 1.313 1.286c0 .71-.587 1.285-1.313 1.285z" fill="#fff"/></svg>
                  <svg width="22" height="20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M21.534 7.32L14.315.873c-.632-.565-1.627-.107-1.627.77v3.395C6.098 5.117.874 6.482.874 12.94c0 2.605 1.623 5.187 3.418 6.536.56.421 1.358-.107 1.152-.79-1.86-6.15.882-7.783 7.242-7.877v3.728c0 .878.997 1.334 1.628.77l7.22-6.446a1.041 1.041 0 000-1.54z" fill="#fff"/></svg>
                </div>
                <div class="tiktok_user_bottom">
                  <span *ngIf="item.image !== ''; else userProfile">
                    <div class="pre_user_left">
                      <app-image-preview [imageUrl]="item.image" [class]="'account-list-image'" [isVideo]="false"></app-image-preview>
                    </div>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="item.provider === sharedService.pinterestProvider">
        <div class="pinterest_img_sec" [class.default_active]="postMedias.length === 0">

          <!--*ngIf="type === 'image' || type === 'gif'"-->
          <ng-container>
            <!--instagram-->
            <!-- <ng-container *ngIf="item.provider === sharedService.instagramProvider">
              <nz-carousel nzEffect="transform-no-loop" [nzDots]="postMedias.length > 1">
                <div nz-carousel-content *ngFor="let media of postMedias; let i = index">
                  <app-image-preview [imageUrl]="media.media_file" class="preview-post-img" [isVideo]="media.file_type==='video'"></app-image-preview>
                </div>
              </nz-carousel>
            </ng-container> -->

            <!--Other then instagram-->
            <ng-container *ngIf="item.provider !== sharedService.instagramProvider">
              <div class="post_img_structure_in" [ngClass]="post_img_class">
                <!-- <ng-container *ngIf="postMedias.length > 0">
                    <app-image-preview [imageUrl]="postMedias[0].media_file" class="preview-post-img" [isVideo]="postMedias[0].file_type==='video'"></app-image-preview>
                </ng-container> -->
                <ng-container *ngIf="postMedias.length > 0">
                  <ng-container *ngIf="postMedias[0].file_type=== 'image'">
                    <app-image-preview [imageUrl]="postMedias[0].media_file" class="preview-post-img" [isVideo]="false"></app-image-preview>
                  </ng-container>
                  <ng-container *ngIf="postMedias[0].file_type=== 'video'">
                    <app-image-preview [imageUrl]="postMedias[0].media_file" class="preview-post-img" [isVideo]="true"></app-image-preview>
                  </ng-container>
                </ng-container>

              </div>
            </ng-container>
          </ng-container>


          <!--<ng-container *ngIf="postMedias.length > 0 && type === 'video'">
            <div class="upload_video">
              <div class="upload_video_in">
                <app-image-preview [imageUrl]="postMedias[0].media_file" class="preview-post-video" [isVideo]="true">
                </app-image-preview>
              </div>
            </div>
          </ng-container>-->

          <div class="pinterest_img_sec_over_sec">
            <div class="over_sec_left">
              <span> <svg width="16" height="16" viewBox="0 0 20 20" fill="#545557"><polygon points="7 12 3 8 0 11 7 18 20 5 17 2"></polygon></svg> </span>
              <span> <svg width="16" height="16" viewBox="0 0 20 20" fill="#545557"><path d="M10.12,4.37 L15.62,9.87 L5.87,19.62 C5.62,19.87 5.37,20 5,20 L1.25,20 C0.5,20 0,19.5 0,18.75 L0,15 C0,14.62 0.12,14.37 0.37,14.12 L10.12,4.37 Z M19.62,4.12 C20.12,4.62 20.12,5.37 19.62,5.87 L17.37,8.12 L11.87,2.62 L14.12,0.37 C14.62,-0.12 15.37,-0.12 15.87,0.37 L19.62,4.12 Z"></path></svg> </span>
            </div>
            <div class="over_sec_right">
              <span> <svg width="16" height="16" viewBox="0 0 20 20" fill="#ffffff"><path d="M19.62,7.12 L12.87,0.37 C12.37,-0.12 11.62,-0.12 11.12,0.37 C10.62,0.87 10.62,1.62 11.12,2.12 L11.87,2.87 L3.37,7.5 L2.12,6.25 C1.62,5.75 0.87,5.75 0.37,6.25 C-0.12,6.75 -0.12,7.5 0.37,8 L2.25,9.87 C2.25,9.87 2.25,9.87 2.25,9.87 L5.25,12.87 L0.75,17.5 L2.5,19.25 L7.12,14.62 L12.12,19.62 C12.75,20.25 13.62,19.87 13.87,19.62 C14.37,19.12 14.37,18.37 13.87,17.87 L12.62,16.62 L17.25,8.25 L18,9 C18.75,9.75 19.5,9.25 19.75,9 C20.12,8.5 20.12,7.62 19.62,7.12 Z M10.62,14.75 L5.25,9.37 L13.62,4.75 L15.25,6.37 L10.62,14.75 Z"></path></svg>
                Save
              </span>
            </div>
          </div>
        </div>

        <div class="des_sec emoji_text">
          <h1>{{title}}</h1>
          <!--<p *ngIf="content">{{content}} </p>-->
          <ng-container *ngIf="isContentLoading">
            <nz-skeleton [nzActive]="true" [nzParagraph]="{ rows: 1 }"></nz-skeleton>
          </ng-container>
          <ng-container *ngIf="!isContentLoading">
            <p *ngIf="otherContent" [innerHTML]="otherContent"></p>
          </ng-container>
          <!-- <p *ngIf="title">{{title}}</p> -->
        </div>

        <div class="pre_user_sec">
          <div class="pre_user_left">
            <app-image-preview [imageUrl]="item.image" [class]="'account-list-image'" [isVideo]="false"></app-image-preview>
          </div>
          <div class="pre_user_right">
            <div class="title">
              <strong>{{item.name }}</strong>
            </div>
            <div class="board_name">
              <!-- <span>Board Name Static</span> -->
              <span>{{boardName}}</span>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="item.provider !== sharedService.pinterestProvider
      && item.provider !== sharedService.youtubeProvider && item.provider !== sharedService.tiktokProvider">
        <div class="pre_user_sec">
          <div class="pre_user_left">
            <app-image-preview [imageUrl]="item.image" [class]="'account-list-image'" [isVideo]="false"></app-image-preview>
            <div class="social-icon-main" *ngIf="!displayActions">
              <!-- <img src="assets/images/Twitter.png" alt="Twitter Logo" class="social-icon"
                *ngIf="post.provider === sharedService.twitterProvider"> -->
              <img *ngIf="item.provider === sharedService.linkedinProvider" src="assets/images/LinkedIn.png" alt="LinkedIn Logo" class="social-icon" />
              <img *ngIf="item.provider === sharedService.facebookProvider" src="assets/images/Facebook.png" alt="Facebook Logo" class="social-icon" />
              <img *ngIf="item.provider === sharedService.instagramProvider" src="assets/images/Instagram.png" alt="Instagram Logo" class="social-icon" />
            </div>
          </div>

          <div class="pre_user_right">
            <div class="title">
              <strong>{{item.name }}</strong>

              <!--Location-->
              <span *ngIf="item.provider === sharedService.facebookProvider && selectedLocation">
                is at <a href="javascript:;">{{ selectedLocation.name }}</a>
              </span>

              <span *ngIf="item.provider === sharedService.instagramProvider && selectedLocation">
                <br><p>{{ selectedLocation.name }}</p>
              </span>
            </div>
            <ng-container *ngIf="item.provider !== sharedService.instagramProvider">
              <div class="time_zone"> <span>{{time}}</span> </div>
            </ng-container>

            <div class="post_action_only_top">
              <ng-container *ngIf="item.provider !== sharedService.instagramProvider">
                <svg width="16" height="16" viewBox="0 0 20 20" fill="#2f3638"><path d="M10,12.5 C8.61,12.5 7.5,11.38 7.5,10 C7.5,8.61 8.61,7.5 10,7.5 C11.38,7.5 12.5,8.61 12.5,10 C12.5,11.38 11.38,12.5 10,12.5 Z M2.5,12.5 C1.11,12.5 0,11.38 0,10 C0,8.61 1.11,7.5 2.5,7.5 C3.88,7.5 5,8.61 5,10 C5,11.38 3.88,12.5 2.5,12.5 Z M17.5,12.5 C16.11,12.5 15,11.38 15,10 C15,8.61 16.11,7.5 17.5,7.5 C18.88,7.5 20,8.61 20,10 C20,11.38 18.88,12.5 17.5,12.5 Z"></path></svg>
              </ng-container>
              <ng-container *ngIf="item.provider === sharedService.instagramProvider">
                <img src="../../../assets/images/ig-menu.svg" alt="">
              </ng-container>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="item.provider !== sharedService.instagramProvider && item.provider !== sharedService.pinterestProvider
              && item.provider !== sharedService.youtubeProvider &&  item.provider !== sharedService.tiktokProvider">
        <div class="des_sec emoji_text">
          <!--<p *ngIf="content"> {{content}} </p>-->

          <ng-container *ngIf="isContentLoading">
            <nz-skeleton [nzActive]="true" [nzParagraph]="{ rows: 1 }"></nz-skeleton>
          </ng-container>
          <ng-container *ngIf="!isContentLoading">
            <ng-container *ngIf="availablePlatformForMention.includes(item.provider)">
              <ng-container *ngIf="item.provider === this.sharedService.facebookProvider">
                <p *ngIf="content" [innerHTML]="facebookContent"></p>
              </ng-container>
              <ng-container *ngIf="item.provider === this.sharedService.instagramProvider">
                <p *ngIf="content" [innerHTML]="instagramContent"></p>
              </ng-container>
              <ng-container *ngIf="item.provider === this.sharedService.twitterProvider">
                <p *ngIf="content" [innerHTML]="twitterContent"></p>
              </ng-container>
              <ng-container *ngIf="item.provider === this.sharedService.threadsProvider">
                <p *ngIf="content" [innerHTML]="threadsContent"></p>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="!availablePlatformForMention.includes(item.provider)">
              <p *ngIf="content" [innerHTML]="otherContent"></p>
            </ng-container>
          </ng-container>
        </div>
      </ng-container>

      <ng-container *ngIf="item.provider !== sharedService.pinterestProvider && item.provider !== sharedService.youtubeProvider
      && item.provider !== sharedService.tiktokProvider">
        <div class="img_sec">
          <!--*ngIf="type === 'image' || type === 'gif' || type === 'multi'"-->
          <ng-container>
            <!--instagram-->
            <ng-container *ngIf="item.provider === sharedService.instagramProvider">
              <!-- <nz-carousel nzEffect="transform-no-loop"> -->
                <!-- <div nz-carousel-content *ngFor="let img of images; let i = index">
                  <app-image-preview [imageUrl]="img" class="preview-post-img" [isVideo]="false"></app-image-preview>
                </div>-->
                <div *ngIf="isFeedPost">
                  <nz-carousel nzEffect="transform-no-loop">
                    <div nz-carousel-content *ngFor="let media of images; let i = index">
                      <app-image-preview [imageUrl]="media" [class]="type==='video' ? 'preview-post-video' : 'preview-post-img' " [isVideo]="type==='video'"></app-image-preview>
                    </div>
                  </nz-carousel>
                </div>
                <div *ngIf="!isFeedPost">
                  <nz-carousel nzEffect="transform-no-loop">
                    <div nz-carousel-content *ngFor="let media of postMedias; let i = index">
                      <app-image-preview [imageUrl]="media.media_file" [class]="media.file_type==='video' ? 'preview-post-video' : 'preview-post-img' " [isVideo]="media.file_type==='video'"></app-image-preview>
                    </div>
                  </nz-carousel>
                </div> 
               <!-- </nz-carousel> -->
            </ng-container>


            <!--Other then instagram-->
            <ng-container *ngIf="item.provider !== sharedService.instagramProvider">
              <div class="post_img_structure_in" [class]="platformClass[item.provider]">
                <ng-container *ngIf="images.length>0 && isFeedPost">
                  <ng-container *ngIf="type==='image'">
                    <ng-container *ngFor="let media of images; let i = index">                    
                        <app-image-preview [imageUrl]="media" class="preview-post-img" [isVideo]="false"></app-image-preview>         
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="type==='video'">
                    <div (click)="preview(i + 1)">
                      <app-image-preview [imageUrl]="images[0]" class="preview-post-img" [isVideo]="true"></app-image-preview>
                    </div>
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="postMedias.length>0 && !isFeedPost">
                  <ng-container *ngIf="postMedias[0].file_type==='image'">
                    <ng-container *ngFor="let media of filteredPostMedias; let i = index">
                      <div *ngIf="i < 4 && media.file_type === 'image'" (click)="preview(i + 1)">
                        <app-image-preview [imageUrl]="media.media_file" class="preview-post-img" [isVideo]="false"></app-image-preview>
                        <span>+{{postMedias.length - 3}}</span>
                      </div>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="postMedias[0].file_type==='video'">
                    <div (click)="preview(i + 1)">
                      <app-image-preview [imageUrl]="postMedias[0].media_file" class="preview-post-img" [isVideo]="true"></app-image-preview>
                    </div>
                  </ng-container>
                </ng-container>
              </div>
            </ng-container>
          </ng-container>

          <!--<ng-container *ngIf="postMedias.length > 0 && type === 'video'">
            <div class="upload_video">
              <div class="upload_video_in">
                <app-image-preview [imageUrl]="postMedias[0]" class="preview-post-video" [isVideo]="true"></app-image-preview>
              </div>
            </div>
          </ng-container>-->
        </div>
      </ng-container>

      <ng-container [class]="platformClass[item.provider]">
        <ng-container *ngIf="displayActions && item.provider === sharedService.facebookProvider">
          <div class="post_action_viewonly">
            <div class="like_sec">
              <img src="../../../assets/images/fb-like.svg" alt="Like" />
              <span>Like</span>
            </div>
            <div class="comnt_sec">
              <img src="../../../assets/images/fb-comment.svg" alt="Comment" />
              <span>Comment</span>
            </div>
            <div class="share_sec">
              <img src="../../../assets/images/fb-share.svg" alt="Share" />
              <span>Share</span>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="displayActions && item.provider === sharedService.twitterProvider">
          <div class="twitter_action_view_only">
            <div> <img src="../../../assets/images/tw-reply.svg" alt="" /> </div>
            <div> <img src="../../../assets/images/tw-retweet.svg" alt="" /> </div>
            <div> <img src="../../../assets/images/tw-like.svg" alt="" /> </div>
            <div> <img src="../../../assets/images/tw-direct-message.svg" alt="" /> </div>
          </div>
        </ng-container>

        <ng-container *ngIf="displayActions && item.provider === sharedService.linkedinProvider">
          <div class="linkedin_action_view_only">
            <div>
              <img src="../../../assets/images/li-like.svg" alt="Like" />
              <span>Like</span>
            </div>
            <div>
              <img src="../../../assets/images/li-comment.svg" alt="Comment" />
              <span>Comment</span>
            </div>
            <div>
              <img src="../../../assets/images/li-share.svg" alt="Share" />
              <span>Share</span>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="displayActions && item.provider === sharedService.instagramProvider">
          <div class="instagram_action_view_only">
            <div class="instagram_action_view_only_left">
              <div> <img src="../../../assets/images/ig-like-heart.svg" alt="" /> </div>
              <div> <img src="../../../assets/images/ig-comment.svg" alt="" /> </div>
              <div> <img src="../../../assets/images/ig-share.svg" alt="" /> </div>
            </div>
            <div class="instagram_action_view_only_right">
              <div> <img src="../../../assets/images/ig-bookmark.svg" alt="" /> </div>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="displayActions && item.provider === sharedService.threadsProvider">
          <div class="twitter_action_view_only threds_icon">
            <div> <img src="../../../assets/images/th-like.svg" alt="" /> </div>
            <div> <img src="../../../assets/images/th-comment.svg" alt="" /> </div>
            <div> <img src="../../../assets/images/th-repost.svg" alt="" /> </div>
            <div> <img src="../../../assets/images/th-share.svg" alt="" /> </div>
          </div>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="item.provider === sharedService.instagramProvider">
        <div class="insta_content">
          <div class="title">
            <strong>{{item.name }}</strong>
          </div>
          <div class="des_sec emoji_text">
            <!--<p *ngIf="content">{{content}} </p>-->

            <ng-container *ngIf="isContentLoading">
              <nz-skeleton [nzActive]="true" [nzParagraph]="{ rows: 1 }"></nz-skeleton>
            </ng-container>
            <ng-container *ngIf="!isContentLoading">
              <p *ngIf="instagramContent" [innerHTML]="instagramContent"></p>
            </ng-container>
          </div>
          <div class="time_zone">
            <span>{{time}}</span>
          </div>
        </div>
      </ng-container>

      <nz-comment *ngIf="((item.provider === sharedService.youtubeProvider && audience === 'false')  && firstComment) ||
          ((item.provider === sharedService.facebookProvider)  && firstComment && item.type !== sharedService.group) ||
          ((item.provider === sharedService.instagramProvider)  && firstComment) ||
          ((item.provider === sharedService.linkedinProvider)  && firstComment)  " class="commentBox">
        <div class="preview_post_wrp">
          <div class="preview_post_header">
            <div class="preview_post_hdr_left">
              <app-image-preview [imageUrl]="item.image" [class]="'account-list-image'" [isVideo]="false"></app-image-preview>
            </div>
            <div class="preview_post_hdr_right">
              <div class="title">
                <strong>{{item.name }}</strong>
              </div>
              <nz-comment-content>
                <p class="contentBody">{{ firstComment.trim() }}</p>
              </nz-comment-content>
            </div>
          </div>
        </div>
      </nz-comment>

      <ng-container *ngIf="comment !== null &&  comment !== ''&& comment !== undefined">
        <div class="comment-section">
          <div nz-row class="comment-inner">
            <div nz-col nzSpan="3">
              <div class="img_wrp">
                <app-image-preview [imageUrl]="profilePic" [class]="'account-list-image'" [isVideo]="false"></app-image-preview>
              </div>
            </div>
            <div nz-col nzSpan="21" class="comment-wrap">
              <div class="title"> <strong>{{name}}</strong> </div>
              <div class="comment"> <p>{{comment}}</p> </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>

    <ng-container *ngIf="item && instagramType === 'reel_post' && item.provider === sharedService.instagramProvider">
      <div class="insta_reel_main">
        <a href="javascript:;" class="play_reel_icon" (click)="playReel()" *ngIf="!autoPlay">
          <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.com/svgjs" width="512" height="512" x="0" y="0" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512" xml:space="preserve" class=""><g><linearGradient id="a" x1="-52.588" x2="390.826" y1="178.158" y2="434.163" gradientUnits="userSpaceOnUse"><stop stop-opacity="1" stop-color="#662dbc" offset="0"></stop><stop stop-opacity="1" stop-color="#ce55ff" offset="1"></stop></linearGradient><path fill="url(#a)" d="M485.036 256a54.706 54.706 0 0 1-27.636 47.868L109.873 504.512a54.742 54.742 0 0 1-55.272 0 54.713 54.713 0 0 1-27.637-47.868V55.356A54.718 54.718 0 0 1 54.6 7.487a54.75 54.75 0 0 1 55.273 0L457.4 208.132A54.708 54.708 0 0 1 485.036 256z" data-original="url(#a)" class=""></path></g></svg>
        </a>

        <div class="img_sec">
          <!--Instagram Reel Thumbnail-->
          <ng-container *ngIf="instaReelThumbnail!==''">
            <app-image-preview [imageUrl]="instaReelThumbnail" class="manage-post-video" [isVideo]="false" ></app-image-preview>
          </ng-container>

          <ng-container *ngIf="instaReelThumbnail===''">
            <app-image-preview class="manage-post-video" [isVideo]="true" [imageUrl]="(isFeedPost)? images[0] :postMedias[0].media_file" [forceAutoPlay]="autoPlay"></app-image-preview>
          </ng-container>
        </div>

        <div class="con_main">
          <div class="con_left">
            <div class="reel_user">
              <app-image-preview [imageUrl]="item.image" [class]="'account-list-image'" [isVideo]="false"></app-image-preview>
              <a href="javascript:;"><h5>{{item.name }}</h5></a>
            </div>
            <div class="reel_des emoji_text">
              <!--<p *ngIf="content">{{content}} </p>-->
              <ng-container *ngIf="isContentLoading">
                <nz-skeleton [nzActive]="true" [nzParagraph]="{ rows: 1 }"></nz-skeleton>
              </ng-container>
              <ng-container *ngIf="!isContentLoading">
                <p *ngIf="instagramContent" [innerHTML]="instagramContent"></p>
              </ng-container>
            </div>
            <div class="reel_music">
              <svg viewBox="0 0 18 18" focusable="false"><path d="M7.166 2.504v1.162a.504.504 0 00.518.504l4.69-.098-10.22 10.234a.49.49 0 000 .714l.826.826a.49.49 0 00.714 0l10.234-10.22-.098 4.69a.504.504 0 00.504.518h1.162A.504.504 0 0016 10.4V2.504A.504.504 0 0015.496 2H7.67a.504.504 0 00-.504.504z"></path></svg>
              <span>{{item.name}}</span>
              <span>Original Audio</span>
            </div>
          </div>

          <div class="con_right">
            <svg width="32" height="170" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g filter="url(#filter0_d_127_27379)" fill="#fff"><path d="M25.488 4.51c-2.71-2.279-6.896-1.937-9.488.702-2.592-2.639-6.778-2.985-9.488-.703-3.524 2.968-3.009 7.805-.496 10.37l8.222 8.376A2.458 2.458 0 0016 24c.67 0 1.294-.262 1.762-.74l8.222-8.377c2.508-2.564 3.033-7.402-.497-10.374zm-1.107 8.788l-8.222 8.377c-.112.113-.206.113-.318 0l-8.222-8.377c-1.711-1.743-2.058-5.043.342-7.064 1.823-1.532 4.636-1.303 6.398.493L16 8.4l1.64-1.673c1.773-1.805 4.585-2.025 6.4-.497 2.394 2.02 2.038 5.339.341 7.068z" /><path fill-rule="evenodd" clip-rule="evenodd" d="M28 60c0 2.007-.493 3.899-1.364 5.561.358 1.504 1.27 5.533.864 5.939-.365.365-3.667-.604-5.419-1.153A11.945 11.945 0 0116 72C9.373 72 4 66.627 4 60s5.373-12 12-12 12 5.373 12 12zm-2 0c0 1.672-.41 3.25-1.137 4.635.299 1.252 1.059 4.61.72 4.948-.304.305-3.055-.503-4.515-.96A9.954 9.954 0 0116 70c-5.523 0-10-4.477-10-10s4.477-10 10-10 10 4.477 10 10zM25.857 96H5.707c-.622 0-.943 1.117-.504 1.576.504.525 7.297 7.171 7.297 7.171s1.985 9.497 2.275 10.691c.193.794 1.61.703 2.015 0 3.006-5.227 6.512-11.493 10.02-17.764v-.001l.055-.097c.357-.639 0-1.576-1.008-1.576zM22 98H9l4.5 4.748 8.5-4.749zm-6 13.745l7.5-11.996-9 4.998 1.5 6.998z" /><path d="M11 152a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM17.5 152a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM22.5 153.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3z" /></g>
              <defs>
                <filter id="filter0_d_127_27379" x="0" y=".994" width="32" height="169.006" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" /> <feOffset dy="2" />
                  <feGaussianBlur stdDeviation="2" /> <feComposite in2="hardAlpha" operator="out" /> <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" />
                  <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_127_27379" />
                  <feBlend in="SourceGraphic" in2="effect1_dropShadow_127_27379" result="shape" />
                </filter>
              </defs>
            </svg>
            <div class="con_right_user">
              <app-image-preview [imageUrl]="item.image" [class]="'account-list-image'" [isVideo]="false"></app-image-preview>
            </div>
          </div>
        </div>
      </div>

      <ng-container *ngIf="comment">
        <nz-comment class="commentBox reel_commentBox">
          <div class="preview_post_wrp">
            <div class="preview_post_header">
              <div class="preview_post_hdr_left">
                <app-image-preview [imageUrl]="item.image" [class]="'account-list-image'" [isVideo]="false"></app-image-preview>
              </div>
              <div class="preview_post_hdr_right">
                <div class="title"><strong>{{item.name }}</strong></div>
                <nz-comment-content><p class="contentBody">{{comment}}</p></nz-comment-content>
              </div>
            </div>
          </div>
        </nz-comment>
      </ng-container>

      <ng-container *ngIf="firstComment">
        <nz-comment class="commentBox reel_commentBox">
          <div class="preview_post_wrp">
            <div class="preview_post_header">
              <div class="preview_post_hdr_left">
                <app-image-preview [imageUrl]="item.image" [class]="'account-list-image'" [isVideo]="false"></app-image-preview>
              </div>
              <div class="preview_post_hdr_right">
                <div class="title"><strong>{{item.name }}</strong></div>
                <nz-comment-content><p class="contentBody">{{firstComment}}</p></nz-comment-content>
              </div>
            </div>
          </div>
        </nz-comment>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="item && instagramType === 'story_post' && item.provider === sharedService.instagramProvider">
      <div class="insta_story_wrapper">
        <!-- {{ type }} -->
        <nz-carousel nzEffect="transform-no-loop" [nzDots]="postMedias.length > 1" (nzAfterChange)="onInstaStoryChange($event)">
          <div nz-carousel-content *ngFor="let media of (isFeedPost) ? images : postMedias; let i = index">
            <div class="insta_story">
              <app-image-preview [imageUrl]="(isFeedPost) ? images[0] : media.media_file" class="manage-post-video" [isVideo]="(isFeedPost) ? type=='video' : media.file_type==='video'" [forceAutoPlay]="postId !== ''"></app-image-preview>
            </div>
          </div>
        </nz-carousel>

        <div class="insta_content">
          <div class="insta_story_indicator_main">
            <div class="insta_story_indicator_in" *ngFor="let media of postMedias;let j = index" [class.active]="currentStoryIndex === j"></div>
          </div>
          <div class="insta_user">
            <div class="img_sec">
              <app-image-preview [imageUrl]="profilePic" [class]="'account-list-image'" [isVideo]="false"></app-image-preview>
            </div>
            <div class="content_sec"><p>{{name}}</p></div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</nz-carousel>


<!--Validation Error-->
<div *ngIf="validationErrors.length>0" class="social_error_main">
  <div class="social_error_in" *ngFor="let validationError of validationErrors">
    <div *ngIf="validationError.errors.length > 0">
      <ul [class]="'social_error_list ul_'+validationError.type">
        <li *ngFor="let error of validationError.errors" class="error-color">
          <app-provider [className]="'preview_post_social_error_icon'" [provider]="validationError.type"></app-provider>
          <span>{{ error }}</span>
        </li>
      </ul>
    </div>
  </div>
</div>


<!--Post Error-->
<div *ngIf="postErrors.length>0" class="social_error_main">
  <div class="social_error_in" *ngFor="let validationError of postErrors">
    <div *ngIf="validationError.errors.length > 0">
      <ul [class]="'social_error_list ul_'+validationError.type">
        <li *ngFor="let error of validationError.errors" class="error-color">
          <app-provider [className]="'preview_post_social_error_icon'" [provider]="validationError.type"></app-provider>
          <strong>{{validationError.name}}</strong>
          <p>{{ error }}</p>
        </li>
      </ul>
    </div>
  </div>
</div>
